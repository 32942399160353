import React from "react";
import { createComponent } from "@lit/react";
import { MdLinearProgress } from '@material/web/progress/linear-progress';

import styles from './LinearProgress.module.scss';

const LinearProgressComponent = createComponent({
    tagName: 'md-linear-progress',
    elementClass: MdLinearProgress,
    react: React,
});

interface IComponentProperties {
    progressValue?: number | undefined
    ariaLabel: string
}

export function LinearProgress (props: IComponentProperties){

    return (
        <div className={styles['LinearProgress']}>
            <LinearProgressComponent 
                value={props.progressValue}
                indeterminate={props.progressValue === undefined}
                aria-label={props.ariaLabel}>         
            </LinearProgressComponent>
        </div>
    );

}