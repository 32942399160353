
import { useTranslation } from 'react-i18next';
import {Icon} from '../../../components/material';

import styles from './NavigationBarItem.module.scss';

interface IComponentProperties {
    iconName: string,
    labelText: string,
    linkUrl: string,
    isActive: boolean,
}

export function NavigationBarItem (props: IComponentProperties) {
    const { t } = useTranslation();
    return (
        <li className={`${styles["NavigationBarItem"]} ${props.isActive? styles['is-active']: ''}`} role='tab'>
            <span className={styles["NavigationBarItem-background"]}></span>
            <span className={styles["NavigationBarItem-stateLayer"]}></span>
            <a href={props.linkUrl} className={styles["NavigationBarItem-content"]}>
                <span className={styles["NavigationBarItem-icon"]}>
                    <Icon name={props.iconName} size={24} ariaHidden={true} isFilled={props.isActive}></Icon>
                </span>
                <span className={styles["NavigationBarItem-labelText"]}>
                    {t(props.labelText)}
                </span>
            </a>
            <div className={styles["NavigationBarItem-outline"]}></div>
        </li>
    );
}