import React from 'react';
import { createComponent } from '@lit/react';
import { MdIconButton} from '@material/web/iconbutton/icon-button';

import {Icon} from '../icon/Icon'; 

import styles from './IconButton.module.scss';

const IconButtonComponent = createComponent({
    tagName: 'md-icon-button',
    elementClass: MdIconButton,
    react: React,
});

interface IComponentProperties {
    ariaLabel: string, // Accessibility Text
    autofocus?: boolean | undefined, // Defines that an element should be focused on page load
    iconName: string, // Defines button value
    id?: string
    isDisabled?: boolean | undefined,
    isSelected?: boolean | undefined,
    onClick: Function, // on clicked event handler
    tooltipText: string,
}

export function IconButton(props: IComponentProperties){

    function handleClick (event: React.MouseEvent<MdIconButton, MouseEvent>) {
        event.stopPropagation();
        event.preventDefault();
        
        props.onClick();
    }

    return (
        <>
            <IconButtonComponent id={props.id} className={`${styles['IconButton']}`} 
                type='button' autoFocus={props.autofocus} disabled={props.isDisabled}
                aria-label={props.ariaLabel} selected={props.isSelected} title={props.tooltipText} toggle={props.isSelected !== undefined}
                onClick={handleClick}>
                <Icon name={props.iconName} isFilled={props.isSelected} ariaHidden={true} size={24}></Icon>
            </IconButtonComponent>
        </>
    );
}
