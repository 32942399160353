import { useState } from "react";

import styles from './AccountSelfCreationConfiguration.module.scss';
import { VisibilityOption } from "../../../../../viewModels/configuration/visibilityOption";
import { SegmentedButton, SegmentedButtonSet, Text } from "../../../../../common/components";
import { usePageStrings } from "../../../../../common/hooks";

interface IComponentProperties {
    onChange:Function,
    visibilityOptionValue:VisibilityOption,
    label:string
    disabled:boolean
  };

  export function AccountSelfCreationConfiguration (props: IComponentProperties) {
      
    const pageStrings = usePageStrings();

     const [visibilityOptionValue, setVisibilityOptionValue] = useState(props.visibilityOptionValue);
  
    function handleVisibilityOptionChanged(selectedValue:VisibilityOption) {
        setVisibilityOptionValue(selectedValue);   
        props.onChange(selectedValue);
    }

    return (
        <>
            <div className={styles["AccountSelfCreationConfiguration"]}>
                <div className={styles["AccountSelfCreationConfiguration-label"]}>
                    <Text fontStyle="body-medium" value={props.label}></Text>
                </div>
                <div className={styles["AccountSelfCreationConfiguration-buttons"]}>
                    <SegmentedButtonSet ariaLabel="">
                        <SegmentedButton 
                            labelText={pageStrings.accountSelfCreationPage.formItem_formConfiguration_required_option}  
                            ariaLabel={pageStrings.accountSelfCreationPage.formItem_formConfiguration_required_option}  
                            selected={visibilityOptionValue.toString() === VisibilityOption.Required.toString()} 
                            value={VisibilityOption.Required.toString()} 
                            onClick={handleVisibilityOptionChanged}
                            disabled={props.disabled}
                        ></SegmentedButton>
                        <SegmentedButton 
                            labelText={pageStrings.accountSelfCreationPage.formItem_formConfiguration_optional_option}  
                            ariaLabel={pageStrings.accountSelfCreationPage.formItem_formConfiguration_optional_option}  
                            selected={visibilityOptionValue.toString() === VisibilityOption.Optional.toString()} 
                            value={VisibilityOption.Optional.toString()} 
                            onClick={handleVisibilityOptionChanged}
                            disabled={props.disabled}
                        ></SegmentedButton>
                        <SegmentedButton 
                            labelText={pageStrings.accountSelfCreationPage.formItem_formConfiguration_hidden_option} 
                            ariaLabel={pageStrings.accountSelfCreationPage.formItem_formConfiguration_hidden_option}  
                            selected={visibilityOptionValue.toString() === VisibilityOption.Hidden.toString()} 
                            value={VisibilityOption.Hidden.toString()} 
                            onClick={handleVisibilityOptionChanged}
                            disabled={props.disabled}
                        ></SegmentedButton>
                    </SegmentedButtonSet>                   
                </div>    
            </div> 
        </>
    );
}
