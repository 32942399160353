import {BaseService, Result} from "../../config";

import { Settings } from "../../../viewModels/configuration/settings";
import { SaveAuthenticationSettingsRequest } from "../../../viewModels/configuration/saveAuthenticationSettingsRequest";
import { SaveAccountSelfCreationSettingsRequest } from "../../../viewModels/configuration/saveAccountSelfCreationSettingsRequest";


class SettingsService extends BaseService {

    public constructor() {
        super("configuration");
    }

    public async getSettings(): Promise<Settings> {
        return await this.httpGet<Settings>("settings");
    }  
       
    public async saveAuthenticationConfiguration(request: SaveAuthenticationSettingsRequest): Promise<Result<boolean>> {
        return await this.httpPost<SaveAuthenticationSettingsRequest, boolean>("settings/authentication", request); 
    }

    public async saveAccountSelfCreationConfiguration(request: SaveAccountSelfCreationSettingsRequest): Promise<Result<boolean>> {
        return await this.httpPost<SaveAccountSelfCreationSettingsRequest, boolean>("settings/accountselfcreation", request); 
    }
   
}

const settingsService = new SettingsService();

export { settingsService };