import { List, ListItem } from "../../../../common/components/material";

import styles from "./SettingsListPane.module.scss";
import { SettingItemIds } from "../SettingsItemIds";
import { usePageStrings } from "../../../../common/hooks";
import { useTranslation } from "react-i18next";

interface IComponentProperties {
  selectedId: string;
  itemSelected: Function;
}

export function SettingsListPane(props: IComponentProperties) {
  const { t } = useTranslation();
  const pageStrings = usePageStrings();

  function renderListItem(itemId: string, headline: string) {
    return (
      <ListItem
        type="button"
        headline={t(headline)}
        trailingIcon="arrow_forward"
        buttonActivated={() => props.itemSelected(itemId)}
        selected={props.selectedId === itemId}
      ></ListItem>
    );
  }

  return (
    <>
      <figure className={styles["SettingsListPane-group"]}>
        <figcaption>Account</figcaption>
        <List>
          {renderListItem(SettingItemIds.authentication, pageStrings.settingsPage.panelTitle_authentication_label)}
          {renderListItem(SettingItemIds.accountSelfCreation,pageStrings.settingsPage.panelTitle_accountSelfCreation_label)}
        </List>
      </figure>
    </>
  );
}
