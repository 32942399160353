import { Department } from "../../../viewModels/user/department";
import { BaseService, Result } from "../../config";

class DepartmentService extends BaseService {

    public constructor() {
        super("user");
    }
      
    public async getDepartments(): Promise<Result<Department[]>> {
        return await this.httpGet("/departments");
    }
}

const departmentService = new DepartmentService();

export { departmentService };