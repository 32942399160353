import { useNavigate } from "react-router-dom";
import { usePageStrings } from "../../../common/hooks";
import { FilledTonalButton, Text } from '../../../common/components';
import styles from "./SignOut.module.scss";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { signOut } from "../../../store/slices/authorization/authorization.slice";

export function SignOut(){

    const pageStrings = usePageStrings();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const authorization = useAppSelector(state=>state.authorization);

    useEffect(()=>{

        if (authorization.isAuthenticated){
            dispatch(signOut());
        }
        
    },[authorization.isAuthenticated,dispatch]);

    function handleReroute(){
        navigate("/authentication/signin");
    }

    return (
        <div className={styles['SignOut']}>

            <header>

                <h1 className={styles['SignOut-heading1']}>
                    <Text fontStyle="headline-large" value={pageStrings.signOutPage.heading_signOut}></Text>
                </h1>

            </header>

            <span className={styles['SignOut-Body']}>
                <Text fontStyle="body-large" value={pageStrings.signOutPage.text_signOut}></Text>
            </span>
            <div className={styles['SignOut-actions']}>
                <FilledTonalButton type="button" value={pageStrings.signOutPage.button_signIn_label} onClick={handleReroute} ></FilledTonalButton>
            </div>
        </div>
   );
  
}
