import React from "react";

import { createComponent } from "@lit/react";
import { MdTextButton } from '@material/web/button/text-button';

import styles from './TextButton.module.scss';
import { Text } from "../../text/Text";

const TextButtonComponent = createComponent({
    tagName: 'md-text-button',
    elementClass: MdTextButton,
    react: React,
});

interface IComponentProperties {
    form?: HTMLFormElement | undefined,
    buttonId?: string | undefined,
    onClick?: Function | undefined,
    value: string,
    type: 'button' | 'submit'
}

export function TextButton (props: IComponentProperties){

    function handleClick(e: any) {
        e.stopPropagation();

        if (props.onClick){
            props.onClick();
        }
    }

    return (
        <>
            <TextButtonComponent id={props.buttonId} onClick={handleClick} className={styles['TextButton']} type={props.type}>
                <Text fontStyle="label-large" value={props.value}></Text>
            </TextButtonComponent>
        </>
    );

}
