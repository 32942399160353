import { useState } from "react";
import { Text } from '../../../common/components/text/Text';
import { Link } from '../../../common/components/link/Link';
import { SendResetPasswordEmail } from './sendResetPasswordEmail/SendResetPasswordEmail';
import { CheckYourEmail } from './checkYourEmail/CheckYourEmail';
import styles from './ForgotCredentials.module.scss';
import { Action, ActionStatus, Result } from "../../../store";
import { BannerMessage } from "../../../common/components";
import { authenticationService } from "../../../store/slices/authentication";
import { usePageStrings } from "../../../common/hooks";
import { appLogService } from "../../../store/slices/appLog";
import { RequestResetPasswordRequest } from "../../../viewModels/authentication/requestResetPasswordRequest";

type SendResetPasswordEmailFormData = {
    emailAddress: string,
}

export function ForgotCredentials() {
    const pageStrings = usePageStrings();
    const [forgotCredentialsAction, setForgotCredentialsAction] = useState(Action.idle());

    const [isEmailSent, setIsEmailSent] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string | null>(null); // Store the email address

    function handleSend(formData: SendResetPasswordEmailFormData) {
        setEmailAddress(formData.emailAddress); // Store the email address on initial send
        send(formData);
    }

    function handleResend() {
        if (emailAddress) {
            send({ emailAddress }); // Use the stored email address when resending
        }
    }

    function send(formData: SendResetPasswordEmailFormData) {
        setForgotCredentialsAction(Action.loading());
        let requestResetPasswordRequest:RequestResetPasswordRequest = {
            email: formData.emailAddress
        }
        authenticationService.resetPasswordRequest(requestResetPasswordRequest).then((result: Result<string>) => {
            if (result.isSuccess) {
                setIsEmailSent(true);
                setForgotCredentialsAction(Action.succeeded());
            } else {
                setForgotCredentialsAction(Action.failed(result.errorMessage));
            }
        }).catch((reason) => {
            setForgotCredentialsAction(Action.failed(pageStrings.general.error_Unexpected));
            appLogService.fatal('ForgotCredentials.tsx', 'handleSend', 'Send', reason);
        });
    }

    return (
        <div className={styles['ForgotCredentials']}>
            <header>
                <h1 className={styles['ForgotCredentials-heading1']}>
                    <Text fontStyle="headline-small" 
                        value={isEmailSent? pageStrings.forgotCredentials.heading_checkYourEmail: pageStrings.forgotCredentials.heading_forgotCredentials}>
                    </Text>
                
                </h1>
            </header>

            {forgotCredentialsAction.message && forgotCredentialsAction.status === ActionStatus.failed &&
                <div className={styles['SignIn-errorMessage']}>
                    <BannerMessage messageType="error" message={forgotCredentialsAction.message}></BannerMessage>
                </div>
            }

            <div className={styles['ForgotCredentials-content']}>
                {isEmailSent ? (
                    <CheckYourEmail onResend={handleResend} emailAddress={emailAddress!} />
                ) : (
                    <SendResetPasswordEmail onSend={handleSend} />
                )}

                <div className={styles['ForgotCredentials-backToSignIn']}>
                    <Link to={'/authentication/signin'} text={pageStrings.forgotCredentials.button_signIn_label}></Link>
                </div>
            </div>
        </div>
    );
}
