import { createSlice } from "@reduxjs/toolkit";
import { userSessionAdapter } from "./userSession.types";

export const userSessionSlice = createSlice({
    name: 'userSession',
    initialState: userSessionAdapter.getInitialState(),
    reducers: {
        
    },
   
});

export default userSessionSlice.reducer;