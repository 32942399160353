import urlService from "../services/url.service";
import { usePageStrings } from "../hooks";
import { SecurityProfileLevel } from "../../viewModels/application/configuration/securityProfileLevel";
import { ActionChecker } from "./useActionChecker";
import { IConfigurationState } from "../../store/slices/configuration";
import { IAuthorizationState } from "../../store/slices/authorization";

export interface INavigationItem {
    id: string,
    iconName: string,
    labelText: string,
    linkUrl: string
}

const useNavigationItems = (actionChecker: ActionChecker, configuration: IConfigurationState, authorization: IAuthorizationState): INavigationItem[] => {
    
    const pageStrings = usePageStrings();

    let navItems: INavigationItem[] = [];

    const homeNavItem = { 
        id: '1', 
        iconName: 'home', 
        labelText: pageStrings.navigationItems.text_Home, 
        linkUrl: urlService.buildLegacyAppUrl('default.aspx')
    };

    const calendarNavItem = { 
        id: '2',
        iconName: 'calendar_month',
        labelText: pageStrings.navigationItems.text_Calendar,
        linkUrl: urlService.buildLegacyAppUrl('app/calendar/calendar.aspx')
    };

    const libraryNavItem = {
        id: '3',
        iconName: 'account_balance',
        labelText: pageStrings.navigationItems.text_Library,
        linkUrl: urlService.buildLegacyAppUrl('app/library/library.aspx')
    };

    const myActivityNavItem = {
        id: '4',
        iconName: 'history_edu',
        labelText: pageStrings.navigationItems.text_MyActivity,
        linkUrl: urlService.buildLegacyAppUrl('app/history/history.aspx')
    };
    
    const reportsNavItem = {
        id: '5',
        iconName: 'data_table',
        labelText: pageStrings.navigationItems.text_Reports,
        linkUrl: urlService.buildLegacyAppUrl('app/analytics/analyticsrun.aspx')
    };

    const analyticsNavItem = {
        id: '6',
        iconName: 'analytics',
        labelText: pageStrings.navigationItems.text_Analytics,
        linkUrl: urlService.buildLegacyAppUrl('app/analytics/analyticsdashboard.aspx')
    };

    const usersNavItem = {
        id: '7',
        iconName: 'groups',
        labelText: pageStrings.navigationItems.text_Users,
        linkUrl: urlService.buildLegacyAppUrl('app/user/user.aspx')
    };

    const adminAreaNavItem = {
        id: '8',
        iconName: 'settings',
        labelText: pageStrings.navigationItems.text_Admin,
        linkUrl: urlService.buildLegacyAppUrl('app/admin/admin.aspx')
    };

    if (actionChecker.canDoAction("home.canAccess.policy")) {
        navItems.push(homeNavItem);
    }

    if (configuration.pages.calendar_IsDisplayed) {
        if (actionChecker.canDoAction("calendar.canAccess.policy")) {
            navItems.push(calendarNavItem);
        }
    }

    if (configuration.pages.library_IsDisplayed || authorization.maxSecurityProfileLevel > SecurityProfileLevel.CompanyAdministrator) {
        if (actionChecker.canDoAction("library.canAccess.policy")) {
            navItems.push(libraryNavItem);
        }
    }

    if (configuration.pages.history_IsDisplayed) {
        if (actionChecker.canDoAction("myActivity.canAccess.policy")) {
            navItems.push(myActivityNavItem);
        }
    }

    if (actionChecker.canDoAction("reports.canAccess.policy")) {
        navItems.push(reportsNavItem);
    }

    if (actionChecker.canDoAction("analytics.canAccess.policy")) {
        navItems.push(analyticsNavItem);
    }

    if (configuration.pages.users_IsDisplayed) {
        if (actionChecker.canDoAction("users.canAccess.policy")) {
            navItems.push(usersNavItem);
        }
    }

    if (actionChecker.canDoAction("adminArea.canAccess.policy")) {
        navItems.push(adminAreaNavItem);
    }

    return navItems

}

export default useNavigationItems;
