import React from "react";
import { createComponent } from "@lit/react";
import { MdFilledButton } from "@material/web/button/filled-button";

import { Text } from '../../text/Text';

import styles from "./FilledButton.module.scss";
import { Icon } from "../icon/Icon";

const FilledButtonComponent = createComponent({
    tagName: "md-filled-button",
    elementClass: MdFilledButton,
    react: React,
    events: {
        "click": "onClick"
    }
});

interface IComponentProperties {
    form?: HTMLFormElement | undefined,
    buttonId?: string | undefined,
    onClick?: Function | undefined,
    value: string,
    iconName?: string,
    type: "button" | "submit",
    disabled?: boolean | undefined,
}

export function FilledButton (props: IComponentProperties) {
    
    function handleClick(e: any) {
        e.stopPropagation();

        if (props.onClick){
            props.onClick();
        }
    }

    return (
        <>
            <FilledButtonComponent onClick={handleClick} className={styles["FilledButton"]} type={props.type} disabled={props.disabled}>
                {props.iconName &&
                    <span slot='icon'>
                        <Icon name={props.iconName} ariaHidden={true} size={18}></Icon>
                    </span>
                }             
                <Text fontStyle="label-large" value={props.value}></Text>
            </FilledButtonComponent>
        </>
    );
}