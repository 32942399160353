import { UserBasic } from "../../../viewModels/user/userBasic";
import { ListItem } from "../../components";

interface IComponentProperties {
    listItemClicked?: Function
    user: UserBasic
}

export function UserListItem (props: IComponentProperties){

    const userInitials = props.user.firstName.substring(0,1) + props.user.lastName.substring(0,1);

    function handleItemClicked (){
        if (props.listItemClicked){
            props.listItemClicked(props.user);
        }
    }

    return (
        <ListItem 
            type="button" 
            headline={props.user.fullName} 
            supportingText={props.user.email} 
            leadingAvatarLabel={userInitials.toUpperCase()}
            buttonActivated={handleItemClicked}></ListItem>
    );

}