import React from "react";
import { createComponent } from "@lit/react";
import { MdSwitch } from '@material/web/switch/switch';
import { Text } from "../../text/Text";

import styles from './Switch.module.scss';

const SwitchComponent = createComponent({
    tagName: 'md-switch',
    elementClass: MdSwitch,
    react: React,
    events:{
        'onChanged': 'change'
    }
});

interface IComponentProperties {
    selected: boolean,
    disabled?: boolean | undefined
    name: string
    required?: boolean | undefined
    label: string
    ariaLabel: string,
    onChanged?: Function | undefined
}

export function Switch (props: IComponentProperties){

    function handleClick (e: any){
        if (props.onChanged){
            props.onChanged(e);
        }
    }

    return (
        <>
            <label className={styles['Switch']}>

                <span className={styles['Switch-label']}>
                    <Text fontStyle="body-large" value={props.label}></Text>
                </span>

                <SwitchComponent
                    name={props.name}
                    showOnlySelectedIcon={true}
                    selected={props.selected}
                    disabled={props.disabled===true}
                    required={props.required===true}
                    aria-label={props.ariaLabel}
                    onChanged={handleClick}>
                </SwitchComponent>
            </label>
        </>
    );

}