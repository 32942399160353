import React from "react";
import { createComponent } from "@lit/react";
import { MdFilledTonalButton } from '@material/web/button/filled-tonal-button';

import { Text } from "../../text/Text";

import styles from './FilledTonalButton.module.scss';

const FilledTonalButtonComponent = createComponent({
    tagName: 'md-filled-tonal-button',
    elementClass: MdFilledTonalButton,
    react: React,
    events: {
        'click': 'onClick'
    }
});

interface IComponentProperties {
    form?: HTMLFormElement | undefined,
    buttonId?: string | undefined,
    onClick?: Function | undefined,
    value: string,
    type: 'button' | 'submit'
}

export function FilledTonalButton (props: IComponentProperties){
    
    function handleClick(e: any) {
        e.stopPropagation();

        if (props.onClick){
            props.onClick();
        }
    }

    return (
        <>
            <FilledTonalButtonComponent onClick={handleClick} className={styles['FilledTonalButton']} type={props.type}>
                <Text fontStyle="label-large" value={props.value}></Text>
            </FilledTonalButtonComponent>
        </>
    );

}