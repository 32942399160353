import { BannerMessage } from "../../../../common/components";
import { ActionStatus, useAppSelector } from "../../../../store"

import styles from './SignInBannerMessage.module.scss';

export function SignInBannerMessage (){

    const authorization = useAppSelector(state => state.authorization);

    return (
        <>
            {authorization.signInErrorMessage && authorization.signInStatus === ActionStatus.failed && (
                <div className={styles["SignInBannerMessage-errorMessage"]}>
                    <BannerMessage messageType="error" message={authorization.signInErrorMessage}></BannerMessage>
                </div>
            )}
        </>
    )
}