import React from 'react';
import { createComponent } from '@lit/react';

import { MdOutlinedSelect } from '@material/web/select/outlined-select';

import styles from './OutlinedSelect.module.scss';
import { useTranslation } from 'react-i18next';

const OutlinedSelectComponent = createComponent({
    tagName: 'md-outlined-select',
    elementClass: MdOutlinedSelect,
    react: React
});

interface IComponentProperties {
    name?:string,
    label: string
    required?: boolean | undefined
    supportingText?: string | undefined
    value?: string | undefined,
    children?: React.ReactNode[] | undefined,
}

/**
   * Max size 1500 items
   */
export function OutlinedSelect (props: IComponentProperties){
    
    const { t } = useTranslation();
    return (
        <div className={styles['OutlinedSelect-container']}>
            <OutlinedSelectComponent 
                name={props.name}
                className={styles['OutlinedSelect']}
                label={t(props.label)}
                aria-label={t(props.label)}
                required={props.required === true}
                supportingText={t(props.supportingText??'')}
                value={props.value}
                >
                {props.children}
            </OutlinedSelectComponent>
        </div>
    );

}