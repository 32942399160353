import { UserBasic } from "../../../viewModels/user/userBasic";
import { UserListItem } from "./UserListItem";

interface IComponentProperties {
    users: UserBasic[],
    onItemClicked?: (user: UserBasic) => void,
}

export function UserList (props: IComponentProperties){
    
    function handleItemClicked (user: UserBasic){
        if (props.onItemClicked){
            props.onItemClicked(user);
        }
    }

    const listItems = props.users.map((user: UserBasic, i: number) => {
        return (
            <li key={i}>            
                <UserListItem user={user} listItemClicked={handleItemClicked}></UserListItem>
            </li>
        )
    });

    return (
        <ul>
            {listItems}
        </ul>
    );
}

