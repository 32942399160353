import { useLayout } from "../../../common/hooks";
import themeService from "../../../common/services/theme.service";
import { TonalPalette } from "./tonalPalette/TonalPalette";

export function Theme () {
   

    useLayout({
        pageTitle: 'Theme'
    });

    return (
        <>

            <TonalPalette title="Primary" palette={themeService.primaryPalette}></TonalPalette>

            <TonalPalette title="Secondary" palette={themeService.secondaryPalette}></TonalPalette>

            <TonalPalette title="Tertiary" palette={themeService.tertiaryPalette}></TonalPalette>

            <TonalPalette title="Neutral" palette={themeService.neutralPalette}></TonalPalette>

            <TonalPalette title="Neutral Variant" palette={themeService.neutralVariantPalette}></TonalPalette>

            <TonalPalette title="Error" palette={themeService.errorPalette}></TonalPalette>

        </>
    )
}