import { t } from 'i18next';
import { Text, TextButton } from '../../../components';
import { usePageStrings } from '../../../hooks';


import styles from './AssumedIdentityBanner.module.scss';

interface IComponentProperties {
    userFullName: string,
    userIdentifier: string,
    revertIdentityClicked?: Function
}

export function AssumedIdentityBanner (props: IComponentProperties) {

    const pageStrings = usePageStrings();
    
    const message = t(pageStrings.layout.text_identityAssumed)
        .replace('{0}', props.userFullName)
        .replace('{1}', props.userIdentifier);

    return (
        <div className={styles['AssumedIdentityBanner']} role='status'>
            <div className={styles['AssumedIdentityBanner-message']}>
                <Text fontStyle={'body-medium'} value={message}></Text>
            </div>
            <div className={styles['AssumedIdentityBanner-link']}>
                <TextButton type='button' value={pageStrings.layout.button_revertIdentity_label} onClick={props.revertIdentityClicked}></TextButton>
            </div>
        </div>
    )
}