import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { IDepartmentState } from "./department.types";
import { departmentService } from "./department.service";
import { Department } from "../../../viewModels/user/department";

const pageStrings = new PageStrings();

const initialState: IDepartmentState = {
    data: [],
    error:'',
    status:ActionStatus.idle
}

export const getDepartments = createAsyncThunk('departments/get', async() =>{
    var response = await departmentService.getDepartments();
    return response.value;
});

export const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {     
    },
    extraReducers: (builder) => { 
        
        builder.addCase(getDepartments.pending,(state:IDepartmentState)=>{
            state.status = ActionStatus.loading;
        });
        
        builder.addCase(getDepartments.rejected,(state:IDepartmentState)=>{
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;

        });
        
        builder.addCase(getDepartments.fulfilled,(state:IDepartmentState,action:PayloadAction<Department[]>)=>{
            state.status = ActionStatus.succeeded;
            state.data = action.payload;
        });

    }
});

// export const {
//     initDepartments
// } = departmentSlice.actions;

export default departmentSlice.reducer;
