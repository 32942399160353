import { Location } from "../../../viewModels/user/location";
import { BaseService, Result } from "../../config";

class LocationService extends BaseService {

    public constructor() {
        super("user");
    }
      
    public async getLocations(): Promise<Result<Location[]>> {
        return await this.httpGet("/locations");
    }
}

const locationService = new LocationService();

export { locationService };