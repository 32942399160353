import { FilledTonalButton, Text } from '../../../../../../common/components';
import { usePageStrings } from "../../../../../../common/hooks";
import { useAppSelector } from '../../../../../../store';

interface IComponentProperties {
    onAssummeIdentityButtonClicked: Function
    onRevertIdentityButtonClicked: Function
}

export function IdentityContent (props: IComponentProperties) {

    const authorization = useAppSelector((state) => state.authorization);
    const pageStrings = usePageStrings();
    

    function handleAssumeIdentity (){
        props.onAssummeIdentityButtonClicked();
    }

    function handleRevertIdentity(){
        props.onRevertIdentityButtonClicked();
    }

    return (
        <>
            <p>
                <Text value={pageStrings.myProfilePage.text_assumeIdentity} fontStyle='body-medium'></Text>
            </p>
            <div>
                {!authorization.isAssumingIdentity && (
                    <FilledTonalButton buttonId="identity-assumebutton" type='button' 
                        value={pageStrings.myProfilePage.button_assumeIdentity_label} 
                        onClick={handleAssumeIdentity}>
                    </FilledTonalButton>
                )}
                {authorization.isAssumingIdentity && (
                    <FilledTonalButton buttonId="identity-revertbutton" type='button' 
                        value={pageStrings.myProfilePage.button_revertIdentity_label}
                        onClick={handleRevertIdentity}>
                    </FilledTonalButton>
                )}
            </div>
        </>
    );

}