import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { IUserState } from "./user.types";
import { userService } from "./user.service";
import { UserBasic } from "../../../viewModels/user/userBasic";
import PageStrings from "../../../viewModels/pageStrings/pageStrings";

const pageStrings = new PageStrings();

const initialState: IUserState = {
    users: [],
    error: '',
    status: ActionStatus.idle
}

export const getUsers = createAsyncThunk('users/get', async () => {
    var response = await userService.GetUserBasicInfo();
    return response.value;
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
        builder.addCase(getUsers.pending, (state: IUserState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getUsers.rejected, (state: IUserState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected
        });

        builder.addCase(getUsers.fulfilled, (state: IUserState, action: PayloadAction<UserBasic[]>) => {

            state.status = ActionStatus.succeeded;
            state.error = '';
            state.users = action.payload;

        });
    }
});

export default userSlice.reducer;