import { useEffect, useState } from "react";

import { useLayout } from "../../../common/hooks/useLayout";
import { OutlinedTextField, FilledTonalButton, Form, Text, Switch } from '../../../common/components';

import { testService } from "../../../store/slices/test";
import { testHub } from "../../../store/slices/test";

import styles from './InfrastructureTests.module.scss';

export function InfrastructureTests (){
    
    const [signalRMessage, setSignalRMessage] = useState('Not Tested');
    const [databaseConnectionTestResult, setDatabaseConnectionTestResult] = useState('Not Tested');
    
    useLayout({
        pageTitle: 'Infrastructure tests'
    });

    useEffect(() => {
        testHub.onMessageReceived((message: string) => {
            setSignalRMessage(message);
        });
    });

    function handleSubmitTenantTest(formData: any){

        if (formData.subdomain){

            setDatabaseConnectionTestResult('Testing');
            testService.getTenantBySubdomain(formData.subdomain)
                .then(value => setDatabaseConnectionTestResult(value))
                .catch(value => setDatabaseConnectionTestResult('Test Failed'));

        }

    }

    function handleSignalRSendMessage (formData: any){
        
        if (formData.useServerCommunication === 'on'){

            testService.sendMessageWithSignalR({
                message: `SERVER: ${formData.message}`
            });

        } else {
            testHub.sendMessage(`CLIENT: ${formData.message}`);
        }
    }

    return (
        <>
            <section className={styles['InfrastructureTests-section']}>

                <h2>
                    <Text fontStyle="title-large" value="Test Database connection"></Text>
                </h2>

                <Form onSubmit={handleSubmitTenantTest}>
                    <div>
                        <OutlinedTextField label="Subdomain" name="subdomain" placeholder="Subdomain" required={true}></OutlinedTextField>
                    </div>

                    <div>
                        <FilledTonalButton type="submit" value="Test"></FilledTonalButton>

                    </div>

                    <div>
                        <p>
                            <Text fontStyle="label-medium" value="Test result:"></Text>
                        </p>
                        <p>
                            <Text fontStyle="body-medium" value={databaseConnectionTestResult}></Text>
                        </p>
                    </div>
                </Form>

            </section>

            <section className={styles['InfrastructureTests-section']}>

                <h2>
                    <Text fontStyle="title-large" value="SignalR connection"></Text>
                </h2>

                <Form onSubmit={handleSignalRSendMessage}>
                    <div>
                        <OutlinedTextField label="Message" name="message" placeholder="Message" required={true}></OutlinedTextField>
                    </div>

                    <div>
                        <Switch 
                            name="useServerCommunication" 
                            label={"Using server communication"} 
                            ariaLabel={"Using server communication"} 
                            selected={false}
                        ></Switch>
                    </div>

                    <div>
                        <FilledTonalButton type="submit" value="Send"></FilledTonalButton>
                    </div>

                </Form>

                <div>
                    <p>
                        <Text fontStyle="label-medium" value="Test result:"></Text>
                    </p>
                    <p>
                        <Text fontStyle="body-medium" value={signalRMessage}></Text>
                    </p>
                </div>

            </section>
        </>
    );

}