import { UserBasic } from "../../../viewModels/user/userBasic";
import { UserProfile } from "../../../viewModels/user/userProfile";
import { BaseService, Result } from "../../config";

class UserAccountService extends BaseService {

    public constructor() {
        super("useraccount");
    }
      
    public async getUserProfile(): Promise<Result<UserProfile>> {
        return await this.httpGet("/profile");
    }

    public async GetUserBasicInfo(): Promise<Result<UserBasic[]>> {
        return await this.httpGet("/userlist");
    }

}

const userAccountService = new UserAccountService();

export { userAccountService };