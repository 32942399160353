import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appLogService } from '../../../store/slices/appLog';
import {  useAppDispatch } from '../../../store';
import { usePageStrings } from '../../../common/hooks';
import styles from './SSOCallback.module.scss';
import { Text, LinearProgress } from '../../../common/components';
import { signInWithAdfs } from '../../../store/slices/authorization';

export function SSOCallback() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();  
    const pageStrings = usePageStrings();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        
        const currentPath = window.location.pathname;

        if (currentPath.includes('/authentication/sso/adfs-callback')) {
          
            const accessToken = searchParams.get('access_token');

            if (accessToken){
                dispatch(signInWithAdfs({
                    token: accessToken
                }));
            }            

        } else {
            appLogService.error("SSOCallback", "useEffect", "SSOProviderIdentity", "SSO Provider not found");
            navigate("/authentication/signIn");
        }

    }, [dispatch, navigate, searchParams]);   
        
    return (
        <div className={styles["SSOCallback-content"]}>
          <div className={styles["SSOCallback-validating"]}>
            <p>
              <Text fontStyle='body-large' value={pageStrings.resetPasswordPage.text_validatingToken}></Text>
            </p>
            <LinearProgress ariaLabel={pageStrings.resetPasswordPage.text_validatingToken}></LinearProgress>
          </div>
      </div>
    );
}