import { useTranslation } from "react-i18next";


export function useInterpolatedPageStrings( pageStringValue:string,interpolationValues:string[]):string
{
    const { t } = useTranslation();
    
    var value = t(pageStringValue);
    interpolationValues.forEach(
        (interpolationValue,index) => 
            value = value.split(`{${index}}`)
                         .join(t(interpolationValue)));
    
    return value;
}

export default useInterpolatedPageStrings;