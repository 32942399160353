import { Text } from '../../text/Text';
import { Icon } from '../icon/Icon';
import styles from './SegmentedButton.module.scss';

interface IComponentProperties{
    disabled?: boolean | undefined,
    // iconName?: string | undefined
    labelText: string,
    ariaLabel?: string | undefined,
    selected?: boolean | undefined,
    onClick: Function,    
    value: string,
}

export function SegmentedButton (props: IComponentProperties){

    const selectedStyle = props.selected? styles['is-selected']: '';
    const disabledStyle = props.disabled? styles['is-disabled']: '';

    function handleClick(){

        if (props.onClick) {
            props.onClick(props.value);
        }
    }

    return (
        <button className={`${styles['SegmentedButton']} ${selectedStyle} ${disabledStyle}`}
            type={'button'}
            tabIndex={props.disabled? -1: 0} 
            aria-label={props.ariaLabel}
            aria-pressed={props.selected}
            disabled={props.disabled}
            onClick={handleClick}>
                <span className={styles['SegmentedButton-outline']}></span>
                <span className={styles['SegmentedButton-background']}></span>
                <span className={styles['SegmentedButton-stateLayer']}></span>
                <span className={styles['SegmentedButton-content']}>
                    {props.selected &&    
                        <span className={styles['SegmentedButton-icon']}>
                            <Icon name={"check"} size={18}></Icon>
                        </span>
                    }
                    <span className={styles['SegmentedButton-label']}>
                        <Text fontStyle="label-large" value={props.labelText}></Text>
                    </span>
                </span>
        </button>
    );
}