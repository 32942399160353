import { ReactNode } from "react";

import { Elevation } from '../elevation/Elevation';

import styles from './ElevatedCard.module.scss';


interface IComponentProperties {
    className?: string
    children?: ReactNode
}

export function ElevatedCard(props: IComponentProperties){

    return (
        <div className={`${styles['ElevatedCard']} ${props.className??''}`}>
            <Elevation></Elevation>
            <div className={styles["background"]}></div>
            <div className={styles["content"]}>{props.children}</div>
            <div className={styles["outline"]}></div>
        </div>
    );
}
