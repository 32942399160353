import { useWindowSize } from '../../../hooks';
import { WindowSizeType } from '../../../services/windowSize.service';
import styles from './ListDetailLayout.module.scss';

interface IComponentProperties {
    detailPane: React.ReactNode[] | React.ReactNode,
    emptyDetailPane?: React.ReactNode[] | React.ReactNode,
    isDetailPaneOpened: boolean,
    listPane: React.ReactNode[] | React.ReactNode,
};

export function ListDetailLayout (props: IComponentProperties){
    const windowSizeType = useWindowSize();
    const isActiveClassName = props.isDetailPaneOpened ? styles['is-active']: '';

    return (
        <div className={`${styles['ListDetailLayout']}`}>
            <div className={styles['ListDetailLayout-listPane']}>
                {props.listPane}
            </div>
            
           
            <div className={`${styles['ListDetailLayout-background']} ${isActiveClassName}`}></div>
           
           
            <div className={` ${styles['ListDetailLayout-detailPane']} ${isActiveClassName} ${styles[(windowSizeType === WindowSizeType.Compact)? 'compact':'']}`}>
                {props.detailPane}
            </div>
            
            <div className={` ${styles['ListDetailLayout-emptyDetailPane']} ${isActiveClassName}`}>
                {props.emptyDetailPane}
            </div>
        </div>
    );

}
