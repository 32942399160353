import { Text } from '../../../common/components';

import styles from './ModuleError.module.scss';

interface IComponentProperties{
    message: string | null
}

export function ModuleError (props: IComponentProperties){

    return (
        <div className={styles['ModuleError']}>
            <Text fontStyle='display-large' value='Unexpected Error'></Text>
            <Text fontStyle='display-small' value={props.message?? ''}></Text>
        </div>
    );
}