import { BaseService, Result } from "../../config";

import {WriteUILogRequest} from '../../../viewModels/application/writeUILogRequest';

class AppLogService extends BaseService {

  public constructor() {
    super("application");
  }

  public async verbose (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('verbose', { className, functionName, functionContext, message });
  }
  
  public async debug (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('debug', { className, functionName, functionContext, message });
  }

  public async information (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('information', { className, functionName, functionContext, message });
  }

  public async warning (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('warning', { className, functionName, functionContext, message });
  }

  public async error (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('error', { className, functionName, functionContext, message });
  }

  public async fatal (className: string, functionName: string, functionContext: string, message: string) : Promise<Result<boolean>>{
    return await this.log('fatal', { className, functionName, functionContext, message });
  }

  private async log(
    logType: 'verbose' | 'debug' | 'information' | 'warning' | 'error' | 'fatal', 
    request: WriteUILogRequest): Promise<Result<boolean>>{
    request.message = request.message??'No message';
    return await this.httpPost(`logs/${logType}`, request);
  }

}

const appLogService = new AppLogService();

export { appLogService };
