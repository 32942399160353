
import { useTranslation } from 'react-i18next';
import {Icon} from '../../../components/material';

import styles from './NavigationRailItem.module.scss';

interface IComponentProperties {
    iconName: string,
    labelText: string,
    linkUrl: string,
    isActive: boolean,
}

export function NavigationRailItem (props: IComponentProperties) {

    const { t } = useTranslation();
    
    return (
        <li className={`${styles["NavigationRailItem"]} ${props.isActive? styles['is-active']: ''}`} role='tab'>
            <span className={styles["NavigationRailItem-background"]}></span>
            <span className={styles["NavigationRailItem-stateLayer"]}></span>
            <a href={props.linkUrl} className={styles["NavigationRailItem-content"]}>
                <span className={styles["NavigationRailItem-icon"]}>
                    <Icon name={props.iconName} size={24} ariaHidden={true} isFilled={props.isActive}></Icon>
                </span>
                <span className={styles["NavigationRailItem-labelText"]}>
                    {t(props.labelText)}
                </span>
            </a>
            <div className={styles["NavigationRailItem-outline"]}></div>
        </li>
    );
}