import {BaseService} from "../../config";

import { BasicConfiguration } from "../../../viewModels/application/basicConfiguration";
import { Configuration } from "../../../viewModels/application/configuration";


class ConfigurationService extends BaseService {

    public constructor() {
        super("application");
    }
      
    public async getConfigurationBasic(): Promise<BasicConfiguration> {
        return await this.httpGet<BasicConfiguration>("configuration/basic");
    }  

    public async getConfiguration(): Promise<Configuration> {
        return await this.httpGet<Configuration>("configuration");
    }  
       
}

const configurationService = new ConfigurationService();

export { configurationService };