import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { IPracticeAreaState } from "./practiceArea.types";
import { practiceAreaService } from "./practiceArea.service";
import { PracticeArea } from "../../../viewModels/user/practiceArea";

const pageStrings = new PageStrings();

const initialState: IPracticeAreaState = {
  data: [],
  error: "",
  status: ActionStatus.idle,
};

export const getPracticeAreas = createAsyncThunk("practiceArea/get", async () => {
  var response = await practiceAreaService.getPracticeAreas();
  return response.value;
});

export const practiceAreaSlice = createSlice({
  name: "practiceArea",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPracticeAreas.pending, (state: IPracticeAreaState) => {
      state.status = ActionStatus.loading;
    });

    builder.addCase(getPracticeAreas.rejected, (state: IPracticeAreaState) => {
      state.status = ActionStatus.failed;
      state.error = pageStrings.general.error_Unexpected;
    });

    builder.addCase(
      getPracticeAreas.fulfilled,
      (state: IPracticeAreaState, action: PayloadAction<PracticeArea[]>) => {
        state.status = ActionStatus.succeeded;
        state.data = action.payload;
      }
    );
  },
});


export default practiceAreaSlice.reducer;
