import { PolicyName } from "../../../common/hooks/useActionChecker"
import { IObjectState } from "../../config"

export interface ILayoutState extends IObjectState {
    appLayout: IAppLayout,
    canAccessPolicy?: PolicyName | undefined
    initialLoad: IInitialLoad,
    mainContentScrollPosition: number,
    pageTitle: string,
    windowSize: IWindowSize,
    windowSizeType: WindowSizeType,
}

export interface ILayoutState1{
    env: object
}

interface IAppLayout {
    isNavigationDrawerOpened: boolean,
    isProgressVisible: boolean
    authorizationConfiguration: 'default' | 'doubleColumn'
}

interface IInitialLoad{
    isConfigurationLoaded: boolean,
    isTranslationFileLoaded: boolean
}

export enum WindowSizeType {
    None,
    Compact,
    Medium,
    Expanded,
    Large,
    ExtraLarge
}

export interface IWindowSize{
    height: number
    width: number    
}