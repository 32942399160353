import { SendMessageWithSignalRRequest } from "../../../viewModels/Test/sendMessageWithSignalRRequest";
import {BaseService, Result} from "../../config";

class TestService extends BaseService {

    public constructor(){
        super('test');
    }

    public async getTenantBySubdomain(subdomain: string): Promise<string>{
        return await this.httpGet(`tenant/${subdomain}`);
    }

    public async sendMessageWithSignalR(request: SendMessageWithSignalRRequest): Promise<Result<string>> {
        return await this.httpPost('signalr/test', request);
    }
}

const testService = new TestService();

export {testService};

