import React from "react";
import { createComponent } from "@lit/react";
import { MdList } from "@material/web/list/list";

import styles from "./List.module.scss";

const ListComponent = createComponent({
    tagName: "md-list",
    elementClass: MdList,
    react: React,
});

/* unsure if children or items??? */
interface IComponentProperties {
    children?: React.ReactNode[] | React.ReactNode,
}


export function List(props: IComponentProperties) {

    return (
        <>
        <ListComponent className={styles["List"]}>
            {props.children}
        </ListComponent>
        </>
    );
}