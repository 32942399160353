import { createComponent } from "@lit/react";
import { MdSelectOption } from '@material/web/select/select-option';
import React from "react";


const SelectOptionComponent = createComponent({
    tagName: 'md-select-option',
    elementClass: MdSelectOption,
    react: React
});

interface IComponentProperties {
    optionName: string,
    optionValue: string,
    ariaLabel?: string | undefined,
    disabled?: boolean | undefined,
    selected?: boolean | undefined,
}

export function SelectOption(props: IComponentProperties){
  
    return (
        <SelectOptionComponent         
            value={props.optionValue} 
            selected={props.selected === true}
            disabled={props.disabled === true}
            aria-label={props.ariaLabel}
            typeaheadText={props.optionName} 
            displayText={props.optionName}
            >
            <div slot="headline">{props.optionName}</div>
        </SelectOptionComponent>
    );

}