import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { ILocationState } from "./location.types";
import { locationService } from "./location.service";
import { Location } from "../../../viewModels/user/location";

const pageStrings = new PageStrings();

const initialState: ILocationState = {
  data: [],
  error: "",
  status: ActionStatus.idle,
};

export const getLocations = createAsyncThunk("locations/get", async () => {
  var response = await locationService.getLocations();
  return response.value;
});

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {    
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state: ILocationState) => {
      state.status = ActionStatus.loading;
    });

    builder.addCase(getLocations.rejected, (state: ILocationState) => {
      state.status = ActionStatus.failed;
      state.error = pageStrings.general.error_Unexpected;
    });

    builder.addCase(
      getLocations.fulfilled,
      (state: ILocationState, action: PayloadAction<Location[]>) => {
        state.status = ActionStatus.succeeded;
        state.data = action.payload;
      });
  },
});

// export const {
//     initDepartments
// } = departmentSlice.actions;

export default locationSlice.reducer;
