import Home from '../app/home/home/Home';
import { NotFound } from "../app/errors/notFound/NotFound";
import { createRef } from 'react';


const routes = [
    {
        index: true,
        nodeRef: createRef(),
        element: <Home />,
        isIntellek: true
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;