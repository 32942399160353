import { hexFromArgb, TonalPalette as mdTonalPalette } from "@material/material-color-utilities";
import { Text } from "../../../../common/components";

import styles from './TonalPalette.module.scss';

interface IComponentProperties {
    title: string,
    palette: mdTonalPalette
}

export function TonalPalette (props: IComponentProperties) {

    return (
        <section className={styles['TonalPalette']}>
            <h3>
                <Text fontStyle="title-medium" value={props.title}></Text>
            </h3>
            <div className={styles['TonalPalette-palette']}>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(100))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="100"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(99))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="99"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(98))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="98"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(95))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="95"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(90))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="90"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(80))}}>
                    <p style={{color: 'black'}}>
                        <Text fontStyle="label-large" value="80"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(70))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="70"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(60))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="60"></Text>
                    </p>
                </div>
                
                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(50))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="50"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(40))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="40"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(35))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="35"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(30))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="30"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(25))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="25"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(20))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="20"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(15))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="15"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(10))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="10"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(5))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="5"></Text>
                    </p>
                </div>

                <div className={styles['TonalPalette-paletteItem']} style={{background: hexFromArgb(props.palette.tone(0))}}>
                    <p style={{color: 'white'}}>
                        <Text fontStyle="label-large" value="0"></Text>
                    </p>
                </div>

            </div>
        </section>
    )

}