import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { userProfileService } from "./userprofile.service";
import { IUserProfileState } from "./userprofile.types";
import { Profile } from "../../../viewModels/user/profile";

const pageStrings = new PageStrings();

const initialState: IUserProfileState = {
  data: [],
  error: "",
  status: ActionStatus.idle,
};

export const getUserProfiles = createAsyncThunk("userProfiles/get", async () => {
  var response = await userProfileService.getUserProfiles();
  return response.value;
});

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserProfiles.pending, (state: IUserProfileState) => {
      state.status = ActionStatus.loading;
    });

    builder.addCase(getUserProfiles.rejected, (state: IUserProfileState) => {
      state.status = ActionStatus.failed;
      state.error = pageStrings.general.error_Unexpected;
    });

    builder.addCase(
      getUserProfiles.fulfilled,
      (state: IUserProfileState, action: PayloadAction<Profile[]>) => {
        state.status = ActionStatus.succeeded;
        state.data = action.payload;
      }
    );
  },
});

export default userProfileSlice.reducer;
