class UrlService {

    private readonly apiUrl: string
    private readonly appUrl: string
    private readonly hubUrl: string
    private readonly legacyAppUrl: string

    constructor() {

        let apiUrl = this.getApiUrl();

        this.apiUrl = `https://${apiUrl}/api`;
        this.appUrl = this.getHost();
        this.hubUrl = `https://${apiUrl}`;
        this.legacyAppUrl = this.getLegacyAppUrl();
        
    }

    buildAppUrl(path: string): string {
        return this.buildUrl(this.appUrl, path);
    }

    buildHubUrl(path: string): string {
        return this.buildUrl(this.hubUrl, path);
    }

    buildApiUrl(path: string): string {
        return this.buildUrl(this.apiUrl, path);
    }

    buildLegacyAppUrl(path: string): string {
        return this.buildUrl(this.legacyAppUrl, path);
    }

    private buildUrl (baseUrl: string, path: string): string {
        return path.length > 0? `${baseUrl}/${path}`: baseUrl;
    }

    private getHost (){

        let host = '';

        if (process.env.NODE_ENV === 'development'){

            host = 'http://localhost:3000';

        } else {

            host = window.location.host;

        }

        return host;

    }

    private getLegacyAppUrl () {
        
        let legacyAppUrl = '';

        if (process.env.NODE_ENV === 'development'){

            legacyAppUrl = 'http://localhost:8084';

        } else {

            legacyAppUrl = `https://${this.getHost().split('.v4.').join('.')}`;

        }

        return legacyAppUrl;
    }

    private getApiUrl () {
        
        let apiUrl = '';

        if (process.env.NODE_ENV === 'development'){

            apiUrl = 'localhost:5100';

        } else {

            //@ts-ignore 
            apiUrl = window.config.apiUrl;

        }

        return apiUrl;
    }
}

const urlService = new UrlService();

export default urlService;