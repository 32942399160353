
import { useState } from 'react';

import styles from './NavigationBarMoreMenuItem.module.scss';
import { Icon, Menu, MenuItem } from '../../../../components';
import { INavigationItem } from '../../../../hooks/useNavigationItems';
import { useTranslation } from 'react-i18next';

interface IComponentProperties {    
    navigationItems:INavigationItem[],
}

export function NavigationBarMoreMenuItem (props: IComponentProperties) {
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

    const { t } = useTranslation();
    
    var isActive:boolean=false
    function handleMoreMenuClick (){
        setIsMoreMenuOpen(!isMoreMenuOpen);
        isActive=true;
    }
    
    function handleMoreMenuClosed (){
        setIsMoreMenuOpen(false);
        isActive=false;
    }

    const NavigationBarMoreMenuItems = props.navigationItems.map(item => {
        return  <MenuItem
        headline={t(item.labelText)}
        iconName={item.iconName}
        href={item.linkUrl}
      ></MenuItem>
    });

    return (
       <li className={`${styles["NavigationBarMoreMenuItem"]} ${isActive? styles['is-active']: ''}`} role='tab'>
            <span className={styles["NavigationBarMoreMenuItem-background"]}></span>
            <span className={styles["NavigationBarMoreMenuItem-stateLayer"]}></span>
            <div  className={styles["NavigationBarMoreMenuItem-content"]} onClick={handleMoreMenuClick}>
                <span className={styles["NavigationBarMoreMenuItem-icon"]}>
                    <Icon name={'more_horiz'} size={24} ariaHidden={true} ></Icon>
                </span>
                <span className={styles["NavigationBarMoreMenuItem-labelText"]}>
                    More
                </span>
            </div>
            <div id="menu-more" className={styles["NavigationBarMoreMenuItem-outline"]}></div>
        
            <Menu anchor='menu-more' open={isMoreMenuOpen} onClosed={handleMoreMenuClosed}>
                {NavigationBarMoreMenuItems}
            </Menu>
        </li>
    );
}