import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OutlinedTextField } from '../../../common/components/material';
import styles from './ChangePassword.module.scss';
import { PasswordRule,passwordRequirements } from '../../../common/features/password/PasswordRule';
import { usePageStrings,useInterpolatedPageStrings } from '../../../common/hooks';
import { useTranslation } from 'react-i18next';

interface IComponentProperties {
  formValues: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errorMessage: string;
  };
  onInputChange: Function;
}

export function ChangePassword(props: IComponentProperties) {
  
  const { t } = useTranslation();
  const { formValues, onInputChange } = props;
  const pageStrings = usePageStrings();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordPatternError, setPasswordPatternError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const passwordErrorText:string = useInterpolatedPageStrings(pageStrings.resetPasswordPage.error_passwordIncorrectFormat,[pageStrings.resetPasswordPage.formItem_password_label]);
  
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
   
    const value = e.target.value;
    setPassword(value);

    if (!passwordRequirements.every((x) => {
      return new RegExp(x.validation).test(e.target.value);
    })) {
      setPasswordPatternError(true);
      e.target.setCustomValidity(passwordErrorText)
      return;
    }else
    {      
      e.target.setCustomValidity('');
      setPasswordPatternError(false);
      if(e.target.value === confirmPassword)
        {
            setPasswordMatchError(false);
        }
        else
        {
            setPasswordMatchError(true);
        }
      }
      onInputChange();
  };

  const handleConfirmPasswordChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
   
    setConfirmPassword(e.target.value);
    if (password !== e.target.value) {
      e.target.setCustomValidity(t(pageStrings.resetPasswordPage.error_passwordMismatch));
      setPasswordMatchError(true);
      console.log(password,e.target.value,password !== e.target.value,'passwordMatchError',passwordMatchError)
      return;
    }
    else
    {
      e.target.setCustomValidity('');
      setPasswordMatchError(false);
    }
    onInputChange();
  };

  function checkPasswords(e: React.ChangeEvent<HTMLInputElement>)
  {
    console.log('checkPasswords',e.target.value,password,e.target.value === password)
      e.preventDefault();
      if(e.target.value === password)
      {
          setPasswordMatchError(false);
      }
      else
      {
          setPasswordMatchError(true);
      }
      onInputChange();
  }

  function checkPasswordValidity(e: React.ChangeEvent<HTMLInputElement>)
    {
      e.preventDefault();
      onInputChange();
      if (!passwordRequirements.every((x) => {
        return new RegExp(x.validation).test(e.target.value);
      })) {
        setPasswordPatternError(true);
        e.target.setCustomValidity(passwordErrorText)
        return;
      }
      else
      {
        setPasswordPatternError(false);
        e.target.setCustomValidity('')
        return;
      }
      
    }

  return (
    <div className={styles['ChangePassword']}>

      <div className={styles['ChangePassword-content']}>
        
        <OutlinedTextField
          label={pageStrings.changePassword.formItem_currentPassword_label}
          name="currentPassword"
          placeholder={pageStrings.changePassword.formItem_currentPassword_ariaLabel}
          required
          type="password"
          value={formValues.currentPassword}
          onChange={()=>{onInputChange()}}
          autocomplete='current-password'
        />

        <OutlinedTextField
          label={pageStrings.changePassword.formItem_newPassword_label}
          name="newPassword"
          placeholder={pageStrings.changePassword.formItem_newPassword_ariaLabel}
          required
          type="password"
          value={password}          
          onChange={handlePasswordChange}
          
          onInput={checkPasswordValidity}
          autocomplete='new-password'
          isError={passwordPatternError}
          errorText={passwordErrorText}
        />

        <ul>
          {passwordRequirements.map((requirement, index) => (
            <li key={index}>
              <PasswordRule
                password={password}
                label={requirement.label}
                validation={requirement.validation}
              />
            </li>
          ))}
        </ul>

        <OutlinedTextField
          label={pageStrings.changePassword.formItem_confirmNewPassword_label}
          name="confirmNewPassword"
          placeholder={pageStrings.changePassword.formItem_confirmNewPassword_ariaLabel}
          required
          type="password"
          value={confirmPassword}
          autocomplete='confirm-password'          
          isError={passwordMatchError}
          errorText={t(pageStrings.resetPasswordPage.error_passwordMismatch)}          
          onChange={handleConfirmPasswordChange}
          onInput={checkPasswords}
        />

      </div>
    </div>
  );
}

ChangePassword.propTypes = {
  formValues: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmNewPassword: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
};