import { Icon } from '../material';
import { Text } from '../text/Text';

import styles from './BannerMessage.module.scss';

interface IComponentProperties {
    messageType: 'error',
    message: string,
}

export function BannerMessage(props: IComponentProperties) {

    const messageTypeStyle = getMessageTypeStyle();
    const iconName = getIconName();
    
    function getIconName (){
        let iconName = '';

        if (props.messageType === 'error'){
            iconName = 'error';
        }

        return iconName;
    } 

    function getMessageTypeStyle (){
        let messageTypeStyle = '';

        if (props.messageType === 'error'){
            messageTypeStyle = styles['BannerMessage--error'];
        }

        return messageTypeStyle;
    }

    return (
        <div className={`${styles['BannerMessage']} ${messageTypeStyle}`} role='status'>
            <div className={styles['BannerMessage-label']}>
                <Text fontStyle={'body-medium'} value={props.message}></Text>
            </div>
            <div className={styles['BannerMessage-icon']}>
                <Icon name={iconName} size={24}></Icon>
            </div>
        </div>
    );
}