export enum WindowSizeType {
    None,
    Compact,
    Medium,
    Expanded,
    Large,
    ExtraLarge
}

export function getWindowSizeType(windowWidth: number): WindowSizeType{

    var windowSizeType: WindowSizeType = WindowSizeType.None;

    if (windowWidth < 600){

        windowSizeType = WindowSizeType.Compact;

    } else if (600 <= windowWidth && windowWidth < 840){

        windowSizeType = WindowSizeType.Medium;

    } else if (840 <= windowWidth && windowWidth < 1200){

        windowSizeType = WindowSizeType.Expanded;

    } else if (1200 <= windowWidth && windowWidth < 1600){

        windowSizeType = WindowSizeType.Large;

    } else if (1600 <= windowWidth){

        windowSizeType = WindowSizeType.ExtraLarge;

    }

    return windowSizeType;

}