import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISnackbarState, SnackbarActionMessage, SnackbarMessage } from "./snackbar.types";

const initialState: ISnackbarState = {   
    snackBarItems:[],
    visible: false,
    visibleItem:undefined
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {

        addSnackbarMessage:(state: ISnackbarState, action: PayloadAction<SnackbarMessage>)=> {
            if(action.payload.autoDismissDelay < 4  ) action.payload.autoDismissDelay = 4;
            if(action.payload.autoDismissDelay > 10 ) action.payload.autoDismissDelay = 10;            
            state.snackBarItems.push(action.payload);
            showSnackbar(state);
        },
        addSnackbarActionMessage:(state: ISnackbarState, action: PayloadAction<SnackbarActionMessage>)=> {
            state.snackBarItems.push(action.payload);            
            showSnackbar(state);
        },
        dismissSnackbarItem:(state: ISnackbarState) =>{
            state.snackBarItems.shift();
            state.visible= false;
            state.visibleItem= undefined;
            showSnackbar(state);
        },

    },
    
});

export const { addSnackbarMessage,addSnackbarActionMessage,dismissSnackbarItem} = snackbarSlice.actions;

export default snackbarSlice.reducer;

function showSnackbar( state:ISnackbarState)
{
    if(state.snackBarItems.length > 0 )
    {
        if(!state.visible)
        {
            state.visible = true;
            state.visibleItem = state.snackBarItems[0];
        }       
    }

}