
export interface ISnackbarState {
    snackBarItems: ISnackbarItem[],
    visibleItem?: ISnackbarItem,
    visible:boolean
}

export interface ISnackbarItem {
    message: string 
    action?: Function,
    actionText?:string,
    autoDismissDelay?: number,   
}
 
export class SnackbarMessage implements ISnackbarItem
{
    message:string
    autoDismissDelay: number
    constructor(message:string,autoDismissDelay:number)
    {
        this.message = message;
        this.autoDismissDelay = autoDismissDelay;
    }
}

export class SnackbarActionMessage implements ISnackbarItem
{
    message:string
    action: Function
    actionText:string
    constructor(message:string,action:Function,actionText:string)
    {
        this.message = message;
        this.action = action;
        this.actionText = actionText;
    }
}

