import { PracticeArea } from "../../../viewModels/user/practiceArea";
import { BaseService, Result } from "../../config";

class PracticeAreaService extends BaseService {

    public constructor() {
        super("user");
    }
      
    public async getPracticeAreas(): Promise<Result<PracticeArea[]>> {
        return await this.httpGet("/practiceareas");
    }
}

const practiceAreaService = new PracticeAreaService();

export { practiceAreaService };