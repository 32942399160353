import { OutlinedTextField } from "../../../../../../common/components";
import { usePageStrings } from "../../../../../../common/hooks";

interface IComponentProperties {
  clientId: string,
  clientSecret: string,
  // clientSecretExpiryDate: Date,
  loginNameProperty: string,
  tenantName: string,
}

export function SSOProviderAzure(props: IComponentProperties) {
  
  const pageStrings = usePageStrings();
  return (
    <>
        <OutlinedTextField
          name="azure_clientId"
          required
          label={pageStrings.authenticationPage.formItem_clientId_label}
          placeholder={pageStrings.authenticationPage.formItem_clientId_label}
          value={props.clientId}
        ></OutlinedTextField>

        <OutlinedTextField
          name="azure_clientSecret"
          required
          label={pageStrings.authenticationPage.formItem_clientSecret_label}
          placeholder={pageStrings.authenticationPage.formItem_clientSecret_label}
          value={props.clientSecret}
        ></OutlinedTextField>

        {/* <OutlinedTextField
          name="azure_clientSecretExpiryDate"          
          label="Client Secret Expiry Date"
          placeholder="Client Secret Expiry Date"
          value={props.clientSecretExpiryDate.toString()}
        ></OutlinedTextField> */}

        <OutlinedTextField
          name="azure_loginNameProperty"
          required
          label={pageStrings.authenticationPage.formItem_usernamePropertyName_label}
          placeholder={pageStrings.authenticationPage.formItem_usernamePropertyName_label}
          value={props.loginNameProperty}
        ></OutlinedTextField>

        <OutlinedTextField
          name="azure_tenantName"
          required
          label={pageStrings.authenticationPage.formItem_tenantName_label}
          placeholder={pageStrings.authenticationPage.formItem_tenantName_label}
          value={props.tenantName}
        ></OutlinedTextField>
         
    </>
  );
}
