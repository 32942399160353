import { InfrastructureTests } from "../app/intellek/InfrastructureTests/InfrastructureTests";
import { Components } from '../app/intellek/components/Components';
import { NotFound } from "../app/errors/notFound/NotFound";
import { createRef } from 'react';
import { Theme } from "../app/intellek/theme/Theme";

const routes = [
    {
        path: 'infrastructure/tests',
        nodeRef: createRef(),
        element: <InfrastructureTests />,
        isIntellek: true
    },
    {
        path: 'components',
        element: <Components />,
        isIntellek: true
    },
    {
        path: 'theme',
        element: <Theme />,        
        isIntellek: true
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;