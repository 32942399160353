import { PublicClientApplication, IPublicClientApplication } from "@azure/msal-browser";

let msalInstance: IPublicClientApplication | null = null;

/**
 * Function to create MSAL instance if not already created.
 */
export async function createMsalInstance(configuration: {
    azure_ClientId: string;
    azure_TenantId: string;
    azure_RedirectUri?: string;
}) {
    if (!msalInstance) {

        const msalConfig = {
            auth: {
                clientId: configuration.azure_ClientId,
                authority: `https://login.microsoftonline.com/${configuration.azure_TenantId}`,
                redirectUri: configuration.azure_RedirectUri,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            },
        };
        msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize(); // Ensures instance is fully initialized
    }
    return msalInstance;
}

// Export msalInstance to be used across the app
export { msalInstance };


// Login request with the required scopes
export const loginRequest = {
    scopes: ["User.Read"], // Add more scopes as needed
};
