import { SettingItemIds } from "../SettingsItemIds";

import { Settings } from "../../../../viewModels/configuration/settings";

import { AccountSelfCreationDetailPane } from "../accountSelfCreationDetailPane/AccountSelfCreationDetailPane";
import { AuthenticationDetailPane } from "../authenticationDetailPane/AuthenticationDetailPane";

import { IconButton } from "../../../../common/components/material";

import styles from "./SettingsDetailPane.module.scss";
import { usePageStrings } from "../../../../common/hooks";
import PageStrings from "../../../../viewModels/pageStrings/pageStrings";
import { useTranslation } from "react-i18next";

interface IComponentProperties {
  selectedId: string,
  detailPaneClosed: Function,
  settings: Settings | null,
  authenticationFormSubmitted: Function
  accountSelfCreationFormSubmitted: Function
}


export function SettingsDetailPane(props: IComponentProperties) {
  const { t } = useTranslation();
  const pageStrings = usePageStrings();

  if (props.settings === null){
    return (<></>);
  }

  return (
    <section className={styles["SettingsDetailPane"]}>

      
      <h2 className={styles["SettingsDetailPane-header"]}>
        <span>
          <IconButton
            ariaLabel={pageStrings.settingsPage.button_back_label}
            iconName="arrow_back"
            tooltipText={pageStrings.settingsPage.button_back_label}
            onClick={props.detailPaneClosed}
          ></IconButton>
        </span>
        <span className={styles["SettingsDetailPane-headerText"]}>
        {t(getDetailPaneTitle(props.selectedId,pageStrings))}
        </span>
      </h2>

      {props.selectedId === SettingItemIds.authentication && (
        <AuthenticationDetailPane 
          onSubmit={props.authenticationFormSubmitted} 
          authenticationSettings={props.settings.authentication}>
        </AuthenticationDetailPane>
      )}
      {props.selectedId === SettingItemIds.accountSelfCreation && (
        <AccountSelfCreationDetailPane
        onSubmit={props.accountSelfCreationFormSubmitted} 
        accountSelfCreationSettings={props.settings.accountSelfCreation}></AccountSelfCreationDetailPane>
      )}

    </section>
  );
}

function getDetailPaneTitle(itemId: string, pageStrings:PageStrings) {
  var paneTitle = "";
 
  switch (itemId) {
    case SettingItemIds.accountSelfCreation:
      paneTitle = pageStrings.settingsPage.panelTitle_accountSelfCreation_label;
      break;

    case SettingItemIds.authentication:
      paneTitle = pageStrings.settingsPage.panelTitle_authentication_label;
      break;
  }

  return paneTitle;
}

