import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Result } from "../../config/base.types";

import { RequestResetPasswordRequest } from "../../../viewModels/authentication/requestResetPasswordRequest";
import { ResetPasswordRequest } from "../../../viewModels/authentication/resetPasswordRequest";
import { SignInWithPasswordRequest } from "../../../viewModels/authentication/signInWithPasswordRequest";

import { authenticationService } from "./authentication.service";
import { IAuthenticationState } from './authentication.types';
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";

const pageStrings = new PageStrings();

const initialState: IAuthenticationState = {
    claims: {},
    isAuthenticated: false,
    resetPasswordResult: false,
    SignInAction: {
        errorMessage: undefined,
        isFulfilled: false,
        isPending: false,
    },
    token: ""
};

export const signInAction = createAsyncThunk('authentication/signin', async (data: SignInWithPasswordRequest) => {
    const response = await authenticationService.signIn(data);
    return response;
});

export const signOut = createAsyncThunk("authentication/signout", async () => {
    const response = await authenticationService.signOut();
    return response;
});

export const resetPasswordRequest = createAsyncThunk("authentication/password/reset/request", async (data: RequestResetPasswordRequest) => {
    const response = await authenticationService.resetPasswordRequest(data);
    return response;
});

export const resetPassword = createAsyncThunk("authentication/password/reset", async (data: ResetPasswordRequest) => {
    const response = await authenticationService.resetPassword(data);
    return response;
});

export const authenticationSlice = createSlice({    
    name: 'authentication',
    initialState,
    reducers: {

        resetSignInAction: (state: IAuthenticationState) => {
            state.SignInAction = {
                isFulfilled: false,
                isPending: false,
                errorMessage: '',
            }
        }

    },
    extraReducers: (builder) => {

        builder.addCase(signInAction.pending, (state: IAuthenticationState) => {       
            
            state.SignInAction = {
                isPending: true,
                isFulfilled: false,
            };    

            state.claims = {};
            state.isAuthenticated = false;
            state.token = '';
        });

        builder.addCase(signInAction.rejected, (state: IAuthenticationState) => {    

            state.SignInAction = {
                isPending: false,
                isFulfilled: false,
                errorMessage: pageStrings.general.error_Unexpected
            };    

        });

        builder.addCase(signInAction.fulfilled, (state: IAuthenticationState, action: PayloadAction<Result<SignInWithPasswordResponse>>) => {

            let isSuccess = action.payload.isSuccess === true;

            state.SignInAction = {
                isPending: false,
                isFulfilled: isSuccess,
                errorMessage: !isSuccess ? action.payload.errorMessage: undefined
            };    
            
            if (isSuccess){

                state.claims = action.payload.value.permissions;
                state.isAuthenticated = true;
                state.token = action.payload.value.token;
            }            
        });

        builder.addCase(signOut.fulfilled, (state: IAuthenticationState, action: PayloadAction<Result<string>>) => {
            state.isAuthenticated = false;
            state.token = '';
        });

        builder.addCase(signOut.rejected, (state: IAuthenticationState, action: any) => {
            state.errorMessage = "An error occurred while signing out. Please try again.";
        });

        builder.addCase(resetPasswordRequest.fulfilled, (state: IAuthenticationState, action: PayloadAction<Result<string>>) => {
            state.resetPasswordResult = action.payload.isSuccess;
            state.errorMessage = action.payload.errorMessage;
            console.log(action.payload);
        });

        builder.addCase(resetPasswordRequest.rejected, (state: IAuthenticationState, action: any) => {
            state.errorMessage = "An error occurred while resetting your password. Please try again.";
        });
    }
});

export const {
    resetSignInAction
} = authenticationSlice.actions;

export default authenticationSlice.reducer;