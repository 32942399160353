import { JobTitle } from "../../../viewModels/user/jobTitle";
import { BaseService, Result } from "../../config";

class JobTitleService extends BaseService {

    public constructor() {
        super("user");
    }
      
    public async getJobTitles(): Promise<Result<JobTitle[]>> {
        return await this.httpGet("/jobtitles");
    }
}

const jobTitleService = new JobTitleService();

export { jobTitleService };