import React from "react";

import { createComponent } from "@lit/react";
import { MdOutlinedButton } from '@material/web/button/outlined-button';

import styles from './OutlinedButton.module.scss';
import { Text } from "../../text/Text";

const OutlinedButtonComponent = createComponent({
    tagName: 'md-outlined-button',
    elementClass: MdOutlinedButton,
    react: React,
});

interface IComponentProperties {
    form?: HTMLFormElement | undefined,
    buttonId?: string | undefined,
    onClick?: Function | undefined,
    value: string,
    disabled?: boolean | false,
    type: 'button' | 'submit'
}

export function OutlinedButton (props: IComponentProperties){

    function handleClick(e: any) {
        e.stopPropagation();

        if (props.onClick){
            props.onClick();
        }
    }

    return (
        <>
            <OutlinedButtonComponent onClick={handleClick} className={styles['OutlinedButton']} type={props.type} disabled={props.disabled}>
                <Text fontStyle="label-large" value={props.value}></Text>
            </OutlinedButtonComponent>
        </>
    );

}
