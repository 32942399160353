import styles from './AccountSelfCreationConfigurationNoButton.module.scss';
import { Text } from "../../../../../common/components";

interface IComponentProperties {
        label:string
        value:string
    };

export function AccountSelfCreationConfigurationNoButton (props: IComponentProperties) {
    return (
        <>        
            <div className={styles["accountSelfCreationConfigurationNoButton"]}>
                <div className={styles["accountSelfCreationConfigurationNoButton-label"]}>
                    <Text fontStyle="body-medium" value={props.label}></Text>
                </div>
                <div className={styles["accountSelfCreationConfigurationNoButton-value"]}>
                    <Text fontStyle="body-medium" value={props.value}></Text>                
                </div>    
            </div>    
        </>
    );
}
