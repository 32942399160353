import { Icon, Text } from "../../../common/components";
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import styles from './PasswordRule.module.scss';

const pageStrings =  new PageStrings();

interface IComponentProperties {
    password: string;
    label: string;
    validation: string;
}

export function PasswordRule(props: IComponentProperties) {
    const rulePassed: boolean = new RegExp(props.validation).test(props.password);
    const rulePassedStyle = rulePassed ? styles['is-passed'] : '';

    return (
        <section className={`${styles['PasswordRule']} ${rulePassedStyle}`}>
            <span className={styles['PasswordRule-icon']}>
                {rulePassed ? (
                    <Icon ariaHidden name="check_circle" isFilled={true} size={24} />
                ) : (
                    <Icon ariaHidden name="circle" size={24} />
                )}
            </span>
            <p>
                <Text fontStyle="label-medium" value={props.label} />
            </p>
        </section>
    );

}
interface IPasswordRequirement
{
    label:string
    validation:string
}

// List of password requirements
export const passwordRequirements:IPasswordRequirement[] = [
    {
    label: pageStrings.changePassword.text_passwordRequirements_minimumCharacters,
    validation: '.{12,}',
    },
    {
    label: pageStrings.changePassword.text_passwordRequirements_upperCase,
    validation: '[A-Z]',
    },
    {
    label: pageStrings.changePassword.text_passwordRequirements_lowerCase,
    validation: '[a-z]',
    },
    {
    label: pageStrings.changePassword.text_passwordRequirements_number,
    validation: '[0-9]',
    },
    {
    label: pageStrings.changePassword.text_passwordRequirements_specialCharacters,
    validation: '[!@#$%^&*]',
    },
];