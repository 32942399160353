import { OutlinedTextField } from "../../../../../../common/components";
import { usePageStrings } from "../../../../../../common/hooks";

interface IComponentProperties {
  accountName: string,
  appId: string,
  appSecret: string,
  // appSecretExpiryDate: Date,
  authorizationServer?: string | undefined
  loginNameProperty: string,
}

export function SSOProviderOKTA(props: IComponentProperties) {



  const pageStrings = usePageStrings();
  return (
    <>
 
        <OutlinedTextField
          name="okta_accountName"
          required
          label={pageStrings.authenticationPage.formItem_accountName_label}
          placeholder={pageStrings.authenticationPage.formItem_accountName_label}
          value={props.accountName}
        ></OutlinedTextField>

        <OutlinedTextField
          name="okta_appId"
          required
          label={pageStrings.authenticationPage.formItem_appId_label}
          placeholder={pageStrings.authenticationPage.formItem_appId_label}
          value={props.appId}
        ></OutlinedTextField>

        <OutlinedTextField
          name="okta_appSecret"
          required
          label={pageStrings.authenticationPage.formItem_appSecret_label}
          placeholder={pageStrings.authenticationPage.formItem_appSecret_label}
          value={props.appSecret}
        ></OutlinedTextField>

        {/* <OutlinedTextField
          name="okta_appSecretExpiryDate"          
          label="Secret Expiry Date"
          placeholder="Secret Expiry Date"
          value={props.appSecretExpiryDate.toString()}
        ></OutlinedTextField> */}

        <OutlinedTextField
          name="okta_authorizationServer"
          label={pageStrings.authenticationPage.formItem_authorizationServer_label}
          placeholder={pageStrings.authenticationPage.formItem_authorizationServer_label}
          value={props.authorizationServer}
        ></OutlinedTextField>

        <OutlinedTextField
          name="okta_loginNameProperty"
          required
          label= {pageStrings.authenticationPage.formItem_usernamePropertyName_label}
          placeholder={pageStrings.authenticationPage.formItem_usernamePropertyName_label}
          value={props.loginNameProperty}
        ></OutlinedTextField>

    </>
  );
}
