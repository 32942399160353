import { useDispatch } from "react-redux";
import { changePageTitle } from "../../../store/slices/layout/layout.slice";

export function NotFound (){
  
    const dispatch = useDispatch();

    dispatch(changePageTitle('Error 404'));

    return (
        <div>
            Error 404 - Page not found
        </div>
    );
}