import { MyProfile } from "../app/profile/myProfile/MyProfile";
import { NotFound } from "../app/errors/notFound/NotFound";
import { createRef } from 'react';

const routes = [
    {
        path: 'me',
        nodeRef: createRef(),
        element: <MyProfile />
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;