
const LMS_ACTION: string = 'LmsAction';
const LMS_TOKEN: string = 'LmsToken';
const User_Permissions: string = 'UserPermissions'; 

class LocalStorageService {

    getAuthorizationToken (): string{
        return this._getItem(LMS_TOKEN, '');
    }

    getAction (): string{
        return this._getItem(LMS_ACTION, '');
    }

    getUserPermissions (): { [key: string]: string; }{
        return this._getItem(User_Permissions, {});
    }

    setAction (actionName: string): void{
        this._setItem(LMS_ACTION, actionName)
    }

    setAuthorizationToken (token: string){
        this._setItem(LMS_TOKEN, token);        
    }

    setUserPermissions (permissions: { [key: string]: string; }){
        this._setItem(User_Permissions, permissions);        
    }

    removeAction (): void{
        this._removeItem(LMS_ACTION);
    }

    removeAuthorization (){
        this._removeItem(LMS_TOKEN);
        this._removeItem(User_Permissions);
    }
    
    private _getItem<T>(key: string, defaultValue: T) : T {
        const value = localStorage.getItem(key);
  
        if (value) {
            return JSON.parse(value) as T;
        }
    
        return defaultValue;
    }

    private _setItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private _removeItem(key: string){
        localStorage.removeItem(key);
    }

}

const localStorageService = new LocalStorageService();

export { localStorageService };
