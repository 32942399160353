import { BaseService, Result } from "../../config";

class Lms3Service extends BaseService {

    public constructor() {
        super('', true);
    }

    public async clearLms4Cookie(): Promise<string>{
        return await this.httpGet('ClearLms4Cookie');
    }

    public async setLms4Cookie(): Promise<string>{
        return await this.httpGet('SetLms4Cookie');
    }

    public async signedIn(guid: string): Promise<Result<string>> {
        
        return await this.httpGet("SyncSessions/" + guid);
    }

    public async setAdminSession(guid: string, currentUserSessionId: string): Promise<Result<string>> {
        
        return await this.httpGet("SetAdminSession/" + guid + "/" + currentUserSessionId);
    }

    public async revertAdminSession(currentUserSessionId: string): Promise<Result<string>> {
        
        return await this.httpGet("RevertAdminSession/" + currentUserSessionId);
    }

    // web cointroller methods
    public async WriteLms3Cookie(guid: string) : Promise<Result<string>> {
        return await this.httpGet("APIV4/WriteLms3Cookie/" + guid);
    }

    public async RemoveLms3Cookie() : Promise<Result<string>> {
        return await this.httpGet("APIV4/RemoveLms3Cookie");
    }
    // api methods
    public async WriteLms3ApiCookie(guid: string) : Promise<Result<string>> {
        return await this.httpGet("LMSAPI/APIv4/WriteLms3Cookie/" + guid);
    }

    public async RemoveLms3ApiCookie() : Promise<Result<string>> {
        return await this.httpGet("LMSAPI/APIv4/RemoveLms3Cookie");
    }
}

const lms3Service = new Lms3Service();

export {lms3Service};