import { Profile } from "../../../viewModels/user/profile";
import { BaseService, Result } from "../../config";

class UserProfileService extends BaseService {
  public constructor() {
    super("user");
  }

  public async getUserProfiles(): Promise<Result<Profile[]>> {
    return await this.httpGet("/profiles");
  }
}

const userProfileService = new UserProfileService();

export { userProfileService };
