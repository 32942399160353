import { Text } from '../../text/Text';
import { Elevation } from '../elevation/Elevation';
import { TextButton } from '../button/TextButton';

import styles from './Snackbar.module.scss';

interface IComponentProperties {
    action?: Function,
    actionText?: string,
    configuration: 'single-line',
    supportingText: string,
}

export function Snackbar (props: IComponentProperties) {

    const configurationStyle: string = 
        props.configuration === 'single-line'?
        styles['Snackbar--singleLine']: '';

    return (
        <>
            <div className={styles['Snackbar-container'] }>
                <div 
                    className={`${styles['Snackbar']} ${configurationStyle}`} 
                    role='status' 
                    aria-label={props.supportingText}>
                    <Elevation></Elevation>
                    <div className={styles['Snackbar-background']}></div>
                    <div className={styles['Snackbar-content']}>
                        <p className={styles['Snackbar-supportingText']} aria-hidden={true}>
                            <Text fontStyle='body-medium' value={props.supportingText}></Text>      
                        </p>
                        { props.actionText && 
                            <div className={styles['Snackbar-action']}>
                                <TextButton 
                                    type='button'
                                    value={props.actionText}
                                    onClick={props.action}>
                                </TextButton>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}