import axios, { type AxiosInstance, AxiosError } from "axios";
import urlService from "../../common/services/url.service";
import { ActionResult, Result } from './base.types';
import { localStorageService } from "../../common/services/localStorage.service";

export abstract class BaseService {
    private readonly apiUrl: string;
    private readonly appUrl: string;
    private readonly legacyAppUrl: string;
    protected readonly $http: AxiosInstance;
    
    protected constructor(controller: string, isLegacyApp: boolean = false) {

        this.apiUrl = urlService.buildApiUrl('');
        this.appUrl = urlService.buildAppUrl('');
        this.legacyAppUrl = urlService.buildLegacyAppUrl('');

        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        let baseUrl = `${this.apiUrl}/`;

        if (controller.length > 0) {
            baseUrl = `${this.apiUrl}/${controller}/`;
        } else if (isLegacyApp === true){
            baseUrl = `${this.legacyAppUrl}/APIV4`;
        }

        this.$http = axios.create({
            baseURL: baseUrl,
            withCredentials: true,
        });

        // Add an interceptor to handle 403 responses
        this.$http.interceptors.response.use(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response?.status === 403 || error.response?.status === 401) {
                    const responseData = error.response.data as { code?: string };

                    // Check if the response has the specific session expired code
                    if (responseData?.code === "usersession.is.expired") {     

                        localStorageService.setAction('session_expired');

                        // Redirect to login page if the session has expired
                        window.location.href = `/authentication/signin?refUrl=${encodeURIComponent(window.location.href)}`; // Update with your login route
                    }
                }
                // Return a rejected promise to allow handling in individual calls if necessary
                return Promise.reject(error);
            }
        );
    }

    protected async httpPost<TRequest, TResponse>(url: string, data?: TRequest): Promise<Result<TResponse>> {
        var response = await this.$http.post<TRequest, ActionResult<Result<TResponse>>>(url, data, this._getConfig());
        return response.data;
    }

    protected async httpGet<TResponse>(url: string): Promise<TResponse> {
        var response = await this.$http.get<void, ActionResult<TResponse>>(url, this._getConfig());
        return response.data;
    }

    private _getConfig() {

        let token = localStorageService.getAuthorizationToken();

        let authorizationHeader = {};

        if (token) {
            authorizationHeader = {
                'Authorization': `Bearer ${token}`,
            };
        }

        return {
            headers: {
                ...authorizationHeader,
                'LMS_Origin_Url': this.appUrl
            }
        };
    }
}