import React from 'react';

import { createComponent } from '@lit/react';
import {MdMenuItem } from '@material/web/menu/menu-item';
import {Icon} from '../icon/Icon'; 

import styles from './MenuItem.module.scss';

const MenuItemComponent = createComponent({
    tagName: 'md-menu-item',
    elementClass: MdMenuItem,
    react: React
});

interface IComponentProperties{
    headline: string,
    href?: string,
    iconName?: string,
    onSignoutClick?: React.MouseEventHandler,
}

export function MenuItem (props: IComponentProperties){

    return (
        <>
            <MenuItemComponent className={styles['MenuItem']} href={props.href} onClick={props.onSignoutClick}>
                {/* */}
                <div slot="headline" className={styles['MenuItem-content']}>
                  
                    {props.iconName && 
                        <Icon name={props.iconName} size={24}></Icon> 
                    }

                    <span className={styles['MenuItem-headline']}>{props.headline}</span>
                </div>
            </MenuItemComponent>
        </>
    );
}