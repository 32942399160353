import { useEffect, useState } from 'react';
import { Form, Navigate } from 'react-router-dom';
import { OutlinedTextField, FilledButton, FilledTonalButton, LinearProgress } from '../../../common/components/material';

import styles from './ResetPassword.module.scss';
import { ActionStatus, Result } from '../../../store';
import { BannerMessage, Text } from '../../../common/components';
import { authenticationService } from '../../../store/slices/authentication';
import { usePageStrings, useInterpolatedPageStrings } from '../../../common/hooks';
import { appLogService } from '../../../store/slices/appLog';
import { PasswordRule,passwordRequirements  } from '../../../common/features/password/PasswordRule';

import { useTranslation } from 'react-i18next';

export function ResetPassword() {

  const { t } = useTranslation();
  const pageStrings = usePageStrings();
  const [rsetPasswordAction, setResetPasswordAction] = useState(ActionStatus.idle);
  const [tokenValidationAction, setTokenValidationAction] = useState(ActionStatus.idle);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordPatternError, setPasswordPatternError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  useEffect(() => {
    if (tokenValidationAction === ActionStatus.idle) {
      const urlObj = new URL(window.location.href);
      const params = new URLSearchParams(urlObj.search);

      const token = params.get("resetCode") || "";
      const userId = params.get("userId") || "";

      if (token && userId) {
        authenticationService.validateResetPasswordToken({ userId, resetCode: token })
          .then((result: Result<string>) => {
            if (!result.isSuccess || result.value !== 'True') {
              setErrorMessage(pageStrings.resetPasswordPage.error_passwordLinkExpired);
              setTokenValidationAction(ActionStatus.failed);
            }
            else {
              setTokenValidationAction(ActionStatus.succeeded);
            }
          })
          .catch((reason) => {
            setErrorMessage(reason.message || pageStrings.general.error_Unexpected);
            setTokenValidationAction(ActionStatus.failed);
            appLogService.error('ResetPassword.tsx', 'useEffect', 'Verify Token', reason);
          });
      } else {
        setErrorMessage(pageStrings.general.error_Unexpected);
        setTokenValidationAction(ActionStatus.failed);
        appLogService.error('ResetPassword.tsx', 'useEffect', 'Verify Token', "Token or User Id not found");
      }
    }
  }, [pageStrings, tokenValidationAction]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setPassword(value);
    if(passwordPatternError) setPasswordPatternError(false);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if(passwordMatchError) setPasswordMatchError(false);
    setConfirmPassword(e.target.value);
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!passwordRequirements.every((x) => {
      return new RegExp(x.validation).test(password);
    })) {
      setPasswordPatternError(true);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }


    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);

    const userId = params.get("userId") || "";
    const token = params.get("resetCode") || "";

    authenticationService.resetPassword({
      userId: userId,
      resetCode: token,
      newPassword: password
    }).then((result: Result<string>) => {
      if (result.isSuccess) {
        setResetPasswordAction(ActionStatus.succeeded);
      }
      else {
        setResetPasswordAction(ActionStatus.failed);
      }
    }).catch((reason) => {
      setErrorMessage(reason.message || 'Failed to reset password');
      setResetPasswordAction(ActionStatus.failed);
      appLogService.fatal('Resetpassword.tsx', 'handleSubmit', 'Send', reason);
    });
  };

  const handleReroute = () => {
    window.location.href = '/authentication/credentials/forgot';
  };

  const passwordErrorText:string = useInterpolatedPageStrings(pageStrings.resetPasswordPage.error_passwordIncorrectFormat,[pageStrings.resetPasswordPage.formItem_password_label]);

  return rsetPasswordAction === ActionStatus.succeeded ? (
    <Navigate to="/authentication/signin"></Navigate>
  ) : (
    <div className={styles['ResetPassword']}>
      <header>
        <h1 className={styles['ResetPassword-heading1']}>
          <Text fontStyle='headline-small' value={pageStrings.resetPasswordPage.heading_resetPassword}></Text>
        </h1>
      </header>

      {errorMessage && (
        <div className={styles["ResetPassword-errorMessage"]}>
          <BannerMessage
            messageType="error"
            message={errorMessage}
          ></BannerMessage>
        </div>
      )}

      <div className={styles["ResetPassword-content"]}>
        {tokenValidationAction === ActionStatus.idle && (
          <div className={styles["ResetPassword-validating"]}>
            <p>
              <Text fontStyle='body-large' value={pageStrings.resetPasswordPage.text_validatingToken}></Text>
            </p>
            <LinearProgress ariaLabel={pageStrings.resetPasswordPage.text_validatingToken}></LinearProgress>
          </div>
        )}
        {tokenValidationAction === ActionStatus.failed && (
          <div className={styles['ResetPassword-actions']}>
            <FilledTonalButton type="button" value={pageStrings.resetPasswordPage.button_clickHere_label} onClick={handleReroute} ></FilledTonalButton>
          </div>
        )}
      </div>

      {tokenValidationAction === ActionStatus.succeeded && (
        <Form onSubmit={handleSubmit}>
          <div className={styles["ResetPassword-content"]}>
            <OutlinedTextField
              label={pageStrings.resetPasswordPage.formItem_password_label}
              name="password"
              placeholder={pageStrings.resetPasswordPage.formItem_password_placeholder}
              required
              type="password"
              value={password}
              isError={passwordPatternError}
              errorText={passwordErrorText}
              onChange={handlePasswordChange}
            />
           
            <ul>
              {passwordRequirements.map((requirement, index) => (
                <li key={index}>
                  <PasswordRule
                    password={password}
                    label={requirement.label}
                    validation={requirement.validation}
                  />
                </li>
              ))}
            </ul>

            <OutlinedTextField
              label={pageStrings.resetPasswordPage.formItem_confirmPassword_label}
              name="confirmPassword"
              placeholder={pageStrings.resetPasswordPage.formItem_confirmPassword_placeholder}
              required
              type="password"
              value={confirmPassword}
              isError={passwordPatternError}
              errorText={t(pageStrings.resetPasswordPage.error_passwordMismatch)}
              onChange={handleConfirmPasswordChange}
            />

            <div className={styles["ResetPassword-actions"]}>
              <FilledButton type="submit" value={pageStrings.resetPasswordPage.button_resetPassword_label} ></FilledButton>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default ResetPassword;