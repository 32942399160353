import { Outlet, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { ElevatedCard, Image } from '../../components';

import { RootState, useAppSelector } from '../../../store';
import { useWindowSize } from '../../hooks';
import { WindowSizeType } from '../../services/windowSize.service';

import styles from './AuthenticationLayout.module.scss';
import SnackbarManager from '../appLayout/snackbarManager/SnackbarManager';

interface IComponentProperties{
    configuration: 'default' | 'doubleColumn'
}

export default function AuthenticationLayout(props: IComponentProperties) {

    const configuration = useAppSelector((state: RootState) => state.configuration);
    const location = useLocation();
    const windowSizeType = useWindowSize();

    const configurationStyle = styles[`AuthenticationLayout--${props.configuration}`];

    function cardContent () {
        return (
            <div className={`${styles['AuthenticationLayout-cardContent']}`}>
                <SwitchTransition>
                    <CSSTransition key={location.pathname} timeout={0} classNames={'page'}>
                        <Outlet />
                    </CSSTransition>
                </SwitchTransition>
            </div>
        )
    }

    return (

        <main className={`${styles['AuthenticationLayout']} ${configurationStyle}`}>

            <div className={styles['AuthenticationLayout-companyLogo']}>
                <Image alternativeText='Company Logo'
                    title={configuration.branding.companyName}
                    imageSource={configuration.branding.companyLogo ?? '@/assets/images/logo.png'}
                    aspectRatio={"2:1|1:2"}
                    orientation="landscape"/>
            </div>
            
            <div className={styles['AuthenticationLayout-content']}>
                    
                {windowSizeType !== WindowSizeType.Compact? (
                    <ElevatedCard className={`${styles['AuthenticationLayout-card']}`}>
                        {cardContent()}
                    </ElevatedCard>
                ):(
                    <>
                        {cardContent()}
                    </>
                )}
                <SnackbarManager/>
            </div>

        </main>

    );

}