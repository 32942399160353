import React from 'react';

import { createComponent } from '@lit/react';
import { MdMenu } from '@material/web/menu/menu';

import styles from './Menu.module.scss';

const MenuComponent = createComponent({
    tagName: 'md-menu',
    elementClass: MdMenu,
    react: React,
    events: {
        onClosed: 'closed'
    }
});


interface IComponentProperties {
    anchor: string,
    children?: React.ReactNode[],
    onClosed?: Function,
    open: boolean
}

export function Menu (props: IComponentProperties){
   
    function handleClosed (event: any) {

        if (props.onClosed){

            props.onClosed();

        }
    }

    return (
        <>
            <MenuComponent anchor={props.anchor} open={props.open} onClosed={handleClosed} className={styles['Menu']}>
                {props.children}
            </MenuComponent>
        </>
    );

}