import { UserBasic } from "../../../viewModels/user/userBasic";
import { BaseService, Result } from "../../config";

class UserService extends BaseService {

    constructor() {
        super('');
        
    }

    public async GetUserBasicInfo(): Promise<Result<UserBasic[]>> {
        return await this.httpGet("/useraccount/userlist");
    }
}

const userService = new UserService();

export {userService};