import styles from './Divider.module.scss';

export function Divider(){
    return (
        <div className={styles['Divider']}>
            <div className={styles['Divider-content']}>

            </div>
        </div>
    );
}