import { combineReducers } from "redux";
import realtimeReducer from "./realTimeSlices/realtime.reducer";

import authenticationReducer from "./slices/authentication/authentication.slice";
import authorizationReducer from './slices/authorization/authorization.slice';
import layoutReducer from "./slices/layout/layout.slice";
import configurationReducer from "./slices/configuration/configuration.slice";
import snackbarReducer from "./slices/snackbar/snackbar.slice";
import departmentReducer from "./slices/department/department.slice";
import  userProfileReducer  from "./slices/userprofile/userprofile.slice";
import jobTitleReducer from "./slices/jobTitle/jobTitle.slice";
import practiceAreaReducer  from "./slices/practiceArea/practiceArea.slice";
import locationReducer from "./slices/location/location.slice";
import userReducer from './slices/user/user.slice';


export default combineReducers({
    realtime: realtimeReducer,
    authentication: authenticationReducer,
    authorization: authorizationReducer,
    configuration: configurationReducer,
    department:departmentReducer,
    layout: layoutReducer,
    location:locationReducer,
    practiceArea:practiceAreaReducer,
    jobTitle:jobTitleReducer,
    snackbar:snackbarReducer,
    user: userReducer,
    userProfile:userProfileReducer,
});

// Redux share data between components

// page - data specific for that page
//     save something, do what is expected -> saved / error (Try again)

// real time data - config, (SignalR)
//    only changed by SignalR

// UI - layout
//    immediate


// 