import { useState } from "react";
import { Form } from "../../../../common/components/form/Form";
import {
  OutlinedTextField,
  FilledButton,
} from "../../../../common/components/material";

import styles from "./SendResetPasswordEmail.module.scss";
import { Text } from "../../../../common/components";
import { usePageStrings } from "../../../../common/hooks";

interface IComponentProperties {
  onSend: Function;
}

export function SendResetPasswordEmail(props: IComponentProperties) {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const pageStrings = usePageStrings();

  const handleSubmit = async (formData: any) => {
    //event.preventDefault(); // Prevent the default form submission
    setIsSubmitting(true);
    await props.onSend(formData);
    setIsSubmitting(false);
  };


  return (
    <>
      <Form
        onSubmit={handleSubmit}
        className={styles["SendResetPasswordEmail"]}
      >
        <p>
          <Text fontStyle="body-medium" value={pageStrings.forgotCredentials.text_forgotCredentialsDescription}></Text>
        </p>

        <OutlinedTextField
          label={pageStrings.forgotCredentials.formItem_emailAddress_label}
          name="emailAddress"
          placeholder={pageStrings.forgotCredentials.formItem_emailAddress_placeholder}
          required
          type="email"
        ></OutlinedTextField>

        <div className={styles["SendResetPasswordEmail-actions"]}>
          <FilledButton
            type="submit"
            value={pageStrings.forgotCredentials.button_send_label}
            disabled={isSubmitting}
          ></FilledButton>
        </div>
      </Form>
    </>
  );
}

export default SendResetPasswordEmail;