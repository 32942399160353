import { FilledButton, Text } from '../../../common/components';
import styles from './UnexpectedError.module.scss';

export function UnexpectedError () {

    function handleReportBug (){
        window.parent.location = 'mailto:support@intellek.io?subject=Error on the Intellek LMS&body=An unexpected error has occurred, please close your browser and re-launch';
    }

    return (
        <div className={styles['UnexpectedError']}>

            <main className={styles['UnexpectedError-main']}>

                <header className={styles['UnexpectedError-header']}>

                    <h1>
                        <Text fontStyle='display-large' value='Team Intellek is sorry'></Text>
                    </h1>

                </header>

                <div className={styles['UnexpectedError-content']}>

                    <h2>
                        <Text fontStyle='title-large' value='Something is not quite right here'></Text>
                    </h2>

                    <p>
                        <Text fontStyle='body-large' value='An unexpected error has ocurred, please close your browser and re-launch'></Text>
                    </p>

                </div>

                <div className={styles['UnexpectedError-actions']}>

                    <FilledButton type='button' value='Report Bug' onClick={handleReportBug}></FilledButton>

                </div>       
            </main>

        </div>
    );

}