import { ActionStatus, useAppSelector } from "../../../store";

import AppLayout from "../appLayout/AppLayout";
import AuthenticationLayout from "../authenticationLayout/AuthenticationLayout";
import { Splash } from "../../../app/splash/Splash";
import { UnexpectedError } from "../../../app/errors/unexpected/UnexpectedError";
import { MainLayoutState } from "./mainLayoutState/MainLayoutState";

interface IComponentProperties{
    layoutType: 'external' | 'application'
}

export function MainLayout (props: IComponentProperties) {

    const layout = useAppSelector((state) => state.layout);

    return (
        <>
            <MainLayoutState></MainLayoutState>

            {(layout.status === ActionStatus.idle || layout.status === ActionStatus.loading) &&
                <Splash></Splash>
            }

            {layout.status === ActionStatus.failed &&
                <UnexpectedError></UnexpectedError>
            }

            {layout.status === ActionStatus.succeeded && 
                <>        

                    {props.layoutType === 'application' && 
                        <AppLayout></AppLayout>
                    }
        
                    {props.layoutType === 'external' && 
                        <AuthenticationLayout configuration={layout.appLayout.authorizationConfiguration}></AuthenticationLayout>
                    }
        
                </>  
            }
        </>
    );

}


