import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';

// Global styles
import './index.scss';

// App Page
import App from './app/App';
import { Auth0Provider } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from './store';

const AppWithAuth = () => {
  // Use the hook within a functional component
  const configurationAuthentication = useSelector(
    (state: RootState) => state.configuration.authentication
  );

  const providerConfig = {
    domain: configurationAuthentication.okta_AccountName,
    clientId: configurationAuthentication.okta_AppId,
    authorizationParams: {
      redirect_uri: configurationAuthentication.okta_ReturnUrl,
      ...(configurationAuthentication.okta_AuthorizationServer && {
        audience: configurationAuthentication.okta_AuthorizationServer,
      }),
    },
  };

  return (
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppWithAuth />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}