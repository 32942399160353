import { useEffect } from 'react';
import { Text,Dialog} from '../../../../../common/components';
import { closeDialog, openDialog } from '../../../../../common/components/material/dialog/Dialog';
import { ActionStatus, useAppDispatch, useAppSelector } from '../../../../../store';
import { assumeIdentity, revertIdentity } from '../../../../../store/slices/authorization';

import styles from './Identity.module.scss';
import { UserBasic } from '../../../../../viewModels/user/userBasic';
import { usePageStrings } from '../../../../../common/hooks';
import { getUsers } from '../../../../../store/slices/user';
import { IdentityContent } from './identityContent/IdentityContent';
import { UserSelector } from '../../../../../common/features/users/UserSelector';

export function Identity () {

    const assumedIdentityDialogId = 'assumed-identity-dialog';    

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const pageStrings = usePageStrings();

    useEffect(() => {

        if (user.status === ActionStatus.idle){
            dispatch(getUsers());
        }

    }, [dispatch, user]);

    function handleOpenAssumeIdentityDialog (){
        openDialog(assumedIdentityDialogId);
    }

    function handleAssumeIdentity (user: UserBasic) {

        dispatch(assumeIdentity({
            learnerId: user.userId
        }));

        closeDialog(assumedIdentityDialogId);
    }

    function handleRevertIdentity() {     
        dispatch(revertIdentity());
    }

    return (
        <section className={styles['Identity']}>

            <h2>
                <Text value={pageStrings.myProfilePage.heading_identity} fontStyle='title-medium'></Text>
            </h2>

            <IdentityContent onAssummeIdentityButtonClicked={handleOpenAssumeIdentityDialog} onRevertIdentityButtonClicked={handleRevertIdentity}></IdentityContent>
            
            <Dialog 
                id={assumedIdentityDialogId} 
                headline={pageStrings.myProfilePage.heading_selectUser}>
                <UserSelector onListItemClicked={handleAssumeIdentity}></UserSelector>
            </Dialog>

        </section>
    );
}
