import { useAppSelector } from "../../store";
import { IAuthorizationPolicy } from "../../store/slices/authorization";

export type PolicyName = 
    'adminArea.canAccess.policy' |
    'analytics.canAccess.policy' |
    'calendar.canAccess.policy' |
    'home.canAccess.policy' |
    'library.canAccess.policy' | 
    'myActivity.canAccess.policy' |
    'reports.canAccess.policy' |
    'settings.canAccess.policy' |
    'settings.canEdit.policy' |
    'users.canAccess.policy' |
    'users.canAssumeIdentity.policy' ;

export class ActionChecker {

    private _policies: {[key: string]: IAuthorizationPolicy}
    private _userClaims: {[key: string]: string}
    
    constructor(policies: {[key: string]: IAuthorizationPolicy}, userClaims: {[key: string]: string}) {
        this._policies = policies;
        this._userClaims = userClaims;
    }

    canDoAction(policyName: PolicyName): boolean{

        let result = false;
        if (this._policies[policyName]){

            let policy = this._policies[policyName];

            if (this._userClaims[policy.permissionType]){

                let userClaimValue = this._userClaims[policy.permissionType];

                result = policy.allowedPermissionValueNames.includes(userClaimValue);
            }
        }
        
        return result;
    }

}

const useActionChecker = (): ActionChecker => {
    const authorization = useAppSelector((state) => state.authorization);
    
    return new ActionChecker(authorization.policies, authorization.userPermissions);
};

export default useActionChecker;