import {  OutlinedTextField} from "../../../../../../common/components";
import { usePageStrings } from "../../../../../../common/hooks";


interface IComponentProperties {
    clientId: string,
    loginNameProperty: string,
    orgUri: string,
}

export function SSOProviderADFS(props: IComponentProperties) {
    const pageStrings = usePageStrings();
    return (
        <>
            <OutlinedTextField name="adfs_clientId" required
                        label={pageStrings.authenticationPage.formItem_clientId_label}
                        placeholder={pageStrings.authenticationPage.formItem_clientId_label}
                        value={props.clientId}>                        
            </OutlinedTextField>

            <OutlinedTextField name="adfs_loginPropertyName" required 
                        label={pageStrings.authenticationPage.formItem_usernamePropertyName_label}
                        placeholder={pageStrings.authenticationPage.formItem_usernamePropertyName_label}
                        value={props.loginNameProperty}>
            </OutlinedTextField>

            <OutlinedTextField name="adfs_orgUri" required
                        label={pageStrings.authenticationPage.formItem_organizationUrl_label} 
                        placeholder={pageStrings.authenticationPage.formItem_organizationUrl_label} 
                        value={props.orgUri}>
            </OutlinedTextField>           
        </>
    );
}