import { createRef } from "react";
import { Settings } from "../app/admin/settings/Settings";
import { NotFound } from "../app/errors/notFound/NotFound";

const routes = [
    {
        path: 'settings',
        nodeRef: createRef(),
        element: <Settings></Settings>
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;