import { useEffect, useState } from 'react';
import { OutlinedButton } from '../../../../common/components/material';

import styles from './CheckYourEmail.module.scss';
import { Text } from '../../../../common/components';
import { usePageStrings } from '../../../../common/hooks';

interface IComponentProperties {
    onResend: Function;
    emailAddress: string;
}

export function CheckYourEmail(props: IComponentProperties) {
    const [disableResend, setDisableResend] = useState(true);
    const [countdown, setCountdown] = useState(180);

    const pageStrings = usePageStrings();

    useEffect(() => {
        // Start the countdown timer when the component mounts
        if (disableResend) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        setDisableResend(false);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000); // 1 second in milliseconds

            return () => {
                clearInterval(timer);
            };
        }
    }, [disableResend]);

    const handleResend = () => {
        setDisableResend(true);
        setCountdown(180);
        props.onResend();
    };

    return (
        <>
            <div className={styles['CheckYourEmail']}>
                <p>
                    <Text fontStyle='body-medium' value={pageStrings.forgotCredentials.text_emailSent}></Text>
                </p>

                <p>
                    <Text fontStyle='body-medium' value={pageStrings.forgotCredentials.text_checkSpamFolder}></Text>
                </p>

                <div className={styles['CheckYourEmail-actions']}>
                    <OutlinedButton
                        type="button"
                        value={pageStrings.forgotCredentials.button_resend_label}
                        onClick={handleResend}
                        disabled={disableResend}
                    />
                    {disableResend &&
                        <div className={styles['CheckYourEmail-actionCooldown']}>
                            <Text fontStyle='label-medium' value={countdown.toString()}></Text>
                            <Text fontStyle='label-medium' value={pageStrings.forgotCredentials.text_seconds}></Text>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
