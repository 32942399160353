import { useState } from 'react';
import { IconButton, Elevation, Menu, MenuItem } from '../../../components/material';
import { Text } from '../../../../common/components';

import styles from './TopAppBar.module.scss'
import { useTranslation } from 'react-i18next';

interface IComponentProperties {
    headline: string,
    isScrolling: boolean,
    isNavigationMenuOpened: boolean,
    isNotificationVisible: boolean,
    onNavigationMenuButtonClick: React.MouseEventHandler<HTMLButtonElement>, // on clicked event handler
    onNotificationButtonClick: React.MouseEventHandler<HTMLButtonElement>, // on clicked event handler
    onSignoutClick: React.MouseEventHandler,
    showLeadingIcon: boolean
}

export function TopAppBar(props: IComponentProperties){
    const { t } = useTranslation();
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    function handleUserMenuClick (){
        setIsUserMenuOpen(!isUserMenuOpen);
    }

    function handleUserMenuClosed (){
        setIsUserMenuOpen(false);
    }

    return (
        <header className={`${styles['TopAppBar']} ${props.isScrolling?styles['is-scrolling']:''} ${props.showLeadingIcon?styles['TopAppBar--showLeadingIcon']:''}`}>
            <Elevation></Elevation>
            <div className={styles["TopAppBar-background"]}></div>
            <div className={styles["TopAppBar-content"]}>
                
                {props.showLeadingIcon &&
                    <div>
                        {props.isNavigationMenuOpened?
                            <IconButton iconName={'menu_open'} ariaLabel='Close menu' tooltipText='Close menu' 
                                onClick={props.onNavigationMenuButtonClick} ></IconButton>:
                            <IconButton iconName={'menu'} ariaLabel='Open menu' tooltipText='Open menu' 
                                onClick={props.onNavigationMenuButtonClick} ></IconButton>
                        }
                    </div>
                }

                <div className={styles['TopAppBar-headline']}>
                   <Text fontStyle='title-large' value={t(props.headline)}></Text>
                </div>

                { props.isNotificationVisible && 
                <div>
                    <IconButton iconName={'notifications'} ariaLabel='See notifications' tooltipText='See notifications' onClick={props.onNotificationButtonClick} ></IconButton>
                </div>
                }

                <div>
                    <IconButton id="user-menu" iconName={'account_circle'} ariaLabel='User menu' tooltipText='User menu' onClick={handleUserMenuClick} ></IconButton>
                    <Menu anchor='user-menu' open={isUserMenuOpen} onClosed={handleUserMenuClosed}>
                        <MenuItem headline={'My profile'} iconName={'account_box'} href='/profile/me'></MenuItem>
                        <MenuItem headline={'Sign out'} iconName={'logout'} onSignoutClick={props.onSignoutClick}></MenuItem>
                    </Menu>
                </div>
                
            </div>
            <div className={styles["TopAppBar-outline"]}></div>
        </header>

    );
}

