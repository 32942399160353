import { TonalPalette, argbFromHex, hexFromArgb } from '@material/material-color-utilities';

export interface ITheme {
    primaryKeyColor: string,
    secondaryKeyColor: string
}

class ThemeService  {

    primaryPalette: TonalPalette;
    secondaryPalette: TonalPalette;
    tertiaryPalette: TonalPalette;
    neutralPalette: TonalPalette;
    neutralVariantPalette: TonalPalette;
    errorPalette: TonalPalette;

    private readonly _typefaceBrand: string;
    private readonly _typefacePlain: string;
    private readonly _typefaceWeightBold: number;
    private readonly _typefaceWeightMedium: number;
    private readonly _typefaceWeightRegular: number;

    private _keyColorPrimary: string;
    private _keyColorSecondary: string;
    private readonly _keyColorTertiary: string;
    private readonly _keyColorNeutral: string;
    private readonly _keyColorNeutralVariant: string;
    private readonly _keyColorError: string;

    /**
     *
     */
    constructor() {
        
        this._typefaceBrand = "'Roboto Flex', sans-serif";
        this._typefacePlain = "'Roboto Flex', sans-serif";
        this._typefaceWeightBold = 700;
        this._typefaceWeightMedium = 500;
        this._typefaceWeightRegular = 400;

        this._keyColorPrimary = '#3952B0';
        this._keyColorSecondary = '#45B6FF';
        this._keyColorTertiary = '#F4580E';
        this._keyColorNeutral = '#919094';
        this._keyColorNeutralVariant = '#5A5C6D';
        this._keyColorError = '#FF5449';

        this.primaryPalette = TonalPalette.fromInt(argbFromHex(this._keyColorPrimary));
        this.secondaryPalette = TonalPalette.fromInt(argbFromHex(this._keyColorSecondary));
        this.tertiaryPalette = TonalPalette.fromInt(argbFromHex(this._keyColorTertiary));
        this.neutralPalette = TonalPalette.fromInt(argbFromHex(this._keyColorNeutral));
        this.neutralVariantPalette = TonalPalette.fromInt(argbFromHex(this._keyColorNeutralVariant));
        this.errorPalette = TonalPalette.fromInt(argbFromHex(this._keyColorError));
    }

    generateCssVariables (theme: ITheme){

        if (theme.primaryKeyColor) this._keyColorPrimary = theme.primaryKeyColor;
        if (theme.secondaryKeyColor) this._keyColorSecondary = theme.secondaryKeyColor;
       
        this.primaryPalette = TonalPalette.fromInt(argbFromHex(this._keyColorPrimary));
        this.secondaryPalette = TonalPalette.fromInt(argbFromHex(this._keyColorSecondary));
        
        return `
            :root {
                --md-ref-typeface-brand: ${this._typefaceBrand};
                --md-ref-typeface-plain: ${this._typefacePlain};
                --md-ref-typeface-weight-bold: ${this._typefaceWeightBold};
                --md-ref-typeface-weight-medium: ${this._typefaceWeightMedium};
                --md-ref-typeface-weight-regular: ${this._typefaceWeightRegular};

                ${this._generateRefColorVariables()}
            }
        `;
    }

    private _generateRefColorVariables (){

        const tones = [
            100,99,98,96,95,94,92,90,87,80,70,60,50,40,35,30,25,24,22,20,17,15,12,10,6,5,4,0
        ]

        const primaryTones = tones.map(tone => `--md-ref-palette-primary${tone}: ${hexFromArgb(this.primaryPalette.tone(tone))};`);
        const secondaryTones = tones.map(tone => `--md-ref-palette-secondary${tone}: ${hexFromArgb(this.secondaryPalette.tone(tone))};`);
        const tertiaryTones = tones.map(tone => `--md-ref-palette-tertiary${tone}: ${hexFromArgb(this.tertiaryPalette.tone(tone))};`);
        const neutralTones = tones.map(tone => `--md-ref-palette-neutral${tone}: ${hexFromArgb(this.neutralPalette.tone(tone))};`);
        const neutralVariantTones = tones.map(tone => `--md-ref-palette-neutral-variant${tone}: ${hexFromArgb(this.neutralVariantPalette.tone(tone))};`);
        const errorTones = tones.map(tone => `--md-ref-palette-error${tone}: ${hexFromArgb(this.errorPalette.tone(tone))};`);

        return `    
            ${primaryTones.join('\n')}
            ${secondaryTones.join('\n')}
            ${tertiaryTones.join('\n')}
            ${neutralTones.join('\n')}
            ${neutralVariantTones.join('\n')}
            ${errorTones.join('\n')}
        `;
    }

}

const themeService = new ThemeService();

export default themeService;