import React from 'react';
import styles from './Image.module.scss';

interface IComponentProperties {
    alternativeText: string;
    title?: string;
    aspectRatio: AspectRatio;
    className?: string;
    imageSource: string;
    orientation: Orientation;
}

type AspectRatio = 'auto' | 'goldenRatio' | '2:1|1:2';

type Orientation = 'landscape' | 'portrait';

export function Image(props: IComponentProperties) {
    const aspectRatioStyle = getAspectRatioStyle(props.aspectRatio);
    const orientationStyle = styles[`is-${props.orientation}`];

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = '/assets/logo.png';
    };

    return (
        <img
            src={props.imageSource}
            alt={props.alternativeText}
            title={props.title}
            className={`${styles['Image']} ${props.className??''} ${aspectRatioStyle} ${orientationStyle}`}
            onError={handleImageError}
        />
    );
}

function getAspectRatioStyle(aspectRatio: AspectRatio){
    var aspectRatioCssName = '';

    if (aspectRatio === 'auto') aspectRatioCssName = 'auto';
    if (aspectRatio === 'goldenRatio') aspectRatioCssName = 'goldenRatio';
    if (aspectRatio === '2:1|1:2') aspectRatioCssName = 'twoOne';

    return styles[`Image--${aspectRatioCssName}`]
}