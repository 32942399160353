import { AuthorizationPolicy } from "../../../viewModels/application/authorizationPolicy";
import { GetUserSessionPersmissionsResponse } from "../../../viewModels/authentication/getUserSessionPersmissionsResponse";
import { BaseService, Result } from "../../config";

class AuthorizationService extends BaseService {

    public constructor() {
        super('');
    }

    public async getPolicies(): Promise<{[id: string]: AuthorizationPolicy}>{
        return await this.httpGet('application/policies');
    }

    public async getUserPermissions(): Promise<Result<GetUserSessionPersmissionsResponse>>{
        return await this.httpGet('authentication/usersession/permissions');
    }
}

const authorizationService = new AuthorizationService();

export {authorizationService};