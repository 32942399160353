
export interface ActionResult<T> {
    data: T,
    status: number,
    statusText: string
}

export interface Result<T> {
    isSuccess: boolean,
    isFailed: boolean,
    value: T,
    errorMessage: string
}

export interface IObjectState {
    status: ActionStatus,
    error: string | null
}

export enum ActionStatus {
    idle,
    loading,
    succeeded,
    failed,
}

export class Action {

    private _message: string;
    private _status: ActionStatus;

    get message(){
        return this._message;
    }

    get status() {
        return this._status;
    }

    protected constructor(status: ActionStatus, message: string) {        
        this._message = message;
        this._status = status;
    }

    static idle (): Action {
        return new Action(ActionStatus.idle, '');
    }

    static loading (): Action {
        return new Action(ActionStatus.loading, '');
    }

    static failed (error: string): Action {
        return new Action(ActionStatus.failed, error);
    }

    static succeeded (): Action {
        return new Action(ActionStatus.succeeded, '');
    }

}