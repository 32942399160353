import i18n  from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import urlService from '../common/services/url.service';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpBackend)
    .init({ 
        backend: {
            loadPath: `${urlService.buildApiUrl('application/pagestrings/')}{{lng}}`,
            customHeaders: {
                'LMS_Origin_Url': urlService.buildAppUrl('')
            },
            crossDomain: true,
        },
        debug: false, // Enable this option only in Dev mode if required,
        fallbackLng: "en-US",
        load: 'currentOnly',
        nonExplicitSupportedLngs: true,
        supportedLngs: [
            "en-US",
            "en-GB",
            "en", 
            "fr-CA",
            "fr",
            "es",
            "pt",
            "de",
        ],
        react: {
            useSuspense: false
        },
        initImmediate: false
    });

export default i18n;