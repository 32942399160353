import {SignalR} from "../../config";

class TestHub extends SignalR {

    private static instance: TestHub;

    private constructor() {
        super("test");
    }

    public static getInstance(): TestHub {
        
        if (!TestHub.instance){

            TestHub.instance = new TestHub();

            // TestHub.instance.start();

        }

        return TestHub.instance;
    }

    public onMessageReceived(callback: (message: string) => void) {
        this.on("MessageReceived", callback);
    }

    public sendMessage(message: string) {
        this.invoke("SendMessage", message);
    }
}

const testHub = TestHub.getInstance();

export { testHub };