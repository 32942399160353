import React from 'react';
import { createComponent } from '@lit/react';
import { MdIcon } from '@material/web/icon/icon';

import styles from './Icon.module.scss';

const IconComponent = createComponent({
    tagName: 'md-icon',
    elementClass: MdIcon,
    react: React
});

interface IComponentProperties {
    name: string,
    isFilled?: boolean,
    size?: 18 | 20 | 24 | 40 | 48 | undefined,
    ariaHidden?: boolean | undefined,
    testId?: string | undefined
}

export function Icon(props: IComponentProperties){

    const sizeModifierClassName = props.size ? styles[`Icon--size${props.size}`]: '';
    const stateClassName = props.isFilled? styles['is-filled']: '';

    return (
        <IconComponent className={ `${styles['Icon']} ${sizeModifierClassName} ${stateClassName}`} aria-hidden={props.ariaHidden}>
            {props.name}
        </IconComponent>
    );
}

