import { createEntityAdapter } from "@reduxjs/toolkit";
import { IObjectState } from "../../config";

interface IUserSessionState extends IObjectState {
    id: string
    token?: string | undefined,
}

export const userSessionAdapter = createEntityAdapter<IUserSessionState>();

