import { useState } from 'react';
import { Text, FilledTonalButton, getFormData } from '../../../../../common/components';
import styles from './Password.module.scss';
import { Dialog, openDialog, closeDialog } from '../../../../../common/components/material/dialog/Dialog';
import { ChangePassword } from '../../../changePassword/ChangePassword';
import usePageStrings from '../../../../../common/hooks/usePageStrings';
import { appLogService } from '../../../../../store/slices/appLog';
import { authenticationService } from '../../../../../store/slices/authentication';
import { addSnackbarMessage, SnackbarMessage } from '../../../../../store/slices/snackbar';
import { useAppDispatch } from '../../../../../store';

export function Password() {
    
    const pageStrings = usePageStrings();
    const [formValues, setFormValues] = useState<any>({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        errorMessage: '',
    });

    const changePasswordDialogId = 'change-password-dialog';

    const handleInputChange = () => {
        let formData = getFormData(`${changePasswordDialogId}-form`);
        setFormValues(formData);
    };

    const handleDialogOpen = () => {
        setFormValues({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errorMessage: '',
        });
        openDialog(changePasswordDialogId);
    };

    const handleDialogClose = () => {
        setFormValues({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errorMessage: '',
        });
        closeDialog(changePasswordDialogId);
    };

    const dispatch = useAppDispatch();

    const handleSubmit = () => {
        try {
            console.log(formValues)
            if (formValues.newPassword === formValues.confirmNewPassword){
                console.log('submmit')
                
                authenticationService.changePassword({
                    oldPassword: formValues.currentPassword,
                    newPassword: formValues.newPassword,
                }).then(result =>{
                    if (result.isSuccess) {
                        handleDialogClose();
                        let sb =  new SnackbarMessage(pageStrings.general.message_Saved,5) 
                        dispatch(addSnackbarMessage(sb));
                    } else {
                        // Set error message and do not close dialog
                        setFormValues({
                            ...formValues,
                            errorMessage: result.errorMessage,
                        });
                        let sb =  new SnackbarMessage(result.errorMessage,5) 
                        dispatch(addSnackbarMessage(sb));
                    }
                });
                
            }

        } catch (reason: any) {
            // Set error message and do not close dialog
            setFormValues({
                ...formValues,
                errorMessage: reason.message || pageStrings.changePassword.error_ChangePasswordField,
            });
            appLogService.fatal('Password.tsx', 'handleSubmit', 'Send', reason);
        }

        return false;
    };

    return (
        <section className={styles['Password']}>
            <h2><Text value={pageStrings.changePassword.heading_password} fontStyle='title-medium' /></h2>
            <p><Text value={pageStrings.changePassword.text_neverRevealPassword} fontStyle='body-medium' /></p>
            <div>
                <FilledTonalButton 
                    type='button' 
                    value={pageStrings.changePassword.button_changePassword_label} 
                    aria-label={pageStrings.changePassword.button_changePassword_label} 
                    onClick={handleDialogOpen} 
                />
            </div>
            
            <Dialog
                id={changePasswordDialogId}
                headline={pageStrings.changePassword.heading_changePassword_label}
                confirmAction={{ label: pageStrings.changePassword.button_save_label, type: 'submit', action: handleSubmit }}
                dismissAction={{ label: pageStrings.changePassword.button_cancel_label, type: 'button', action: handleDialogClose }}
                className={styles['Password-dialog']}
            >
                <ChangePassword
                    formValues={formValues}
                    onInputChange={handleInputChange}
                />
            </Dialog>
        </section>
    );
}