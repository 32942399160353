import { Image, LinearProgress } from "../../common/components";

import styles from './Splash.module.scss';

export function Splash (){

    return (
        <div className={styles['Splash']}>
            <Image imageSource="/assets/intellek-icon.png" alternativeText="Intellek logo" aspectRatio="auto" orientation="portrait"></Image>
            <LinearProgress ariaLabel="Loading"></LinearProgress>
        </div>
    );
}