
import { usePageStrings } from "../../../../common/hooks";
import { FilledButton, OutlinedTextField, Form, Link, Text} from '../../../../common/components'

import styles from './SignInUserAndPassword.module.scss';
import { ActionStatus, RootState, useAppDispatch, useAppSelector } from "../../../../store";
import { signInWithForm } from "../../../../store/slices/authorization";

interface IComponentProperties {
    refUrl: string
}

export default function SignInUserAndPassword (props: IComponentProperties){
    
    const dispatch = useAppDispatch();
    const pageStrings = usePageStrings();    
    
    const configuration = useAppSelector((state: RootState) => state.configuration);
    const authorization = useAppSelector((state: RootState) => state.authorization);
    
    const formId = "intellek-lms-signin-form";

    function handleFormSubmit (formData: SignInUsernameAndPasswordFormData){
        dispatch(signInWithForm({
            password: formData.password,
            username: formData.username
        }));
    }

    return (
        <>
        
            <Form onSubmit={handleFormSubmit} formId={formId} hiddenSubmit>

                <OutlinedTextField name="username" required
                    label={pageStrings.signInPage.formItem_userName_label}  
                    placeholder={pageStrings.signInPage.formItem_userName_placeholder}
                    formid={formId}
                    
                    >
                </OutlinedTextField>

                <OutlinedTextField name="password" type="password" required
                    label={pageStrings.signInPage.formItem_password_label}  
                    placeholder={pageStrings.signInPage.formItem_password_placeholder}
                    formid={formId} autocomplete="password"
                    >
                </OutlinedTextField>
                
                <p>
                    <Link to={'/authentication/credentials/forgot'} 
                        text={pageStrings.signInPage.link_forgotCredentials_label}>
                    </Link>
                </p>

                <FilledButton type="submit" 
                    value={pageStrings.signInPage.button_signIn_label}
                    disabled={authorization.signInStatus === ActionStatus.loading || authorization.signInStatus === ActionStatus.succeeded}>
                </FilledButton>

                { configuration.accountSelfCreation.isEnabled && 
                    <p className={styles['SignInUserAndPassword-createAccount']}>

                        <span className={styles['SignInUserAndPassword-createAccountLabel']}>
                            <Text fontStyle='body-medium' value={pageStrings.signInPage.text_doNotHaveAccount}></Text>
                        </span>

                        <Link to={'/authentication/account/create'} 
                            text={pageStrings.signInPage.link_createAccount_label}>
                        </Link>

                    </p>    
                }       
            </Form>
        </>
    );
}



type SignInUsernameAndPasswordFormData = {
    username: string;
    password: string;
};