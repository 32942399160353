
import styles from './SegmentedButtonSet.module.scss';

interface IComponentProperties {
    ariaLabel: string,
    children: React.ReactNode[]
}

export function SegmentedButtonSet (props: IComponentProperties) {

    return (
        <div className={styles['SegmentedButtonSet']}>
            <div role="group" className={styles['SegmentedButtonSet-buttons']}
                aria-label={props.ariaLabel}>
                {props.children}
            </div>
        </div>
    );

}