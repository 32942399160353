import { Divider } from '../../../../common/components';
import { Password } from './password/Password';
import { Identity } from './identity/Identity';

import styles from './ProfileActions.module.scss';
import { useActionChecker } from '../../../../common/hooks';
import { useAppSelector } from '../../../../store';

export function ProfileActions() {

    const actionChecker = useActionChecker();

    const authorization = useAppSelector(state => state.authorization);
 
    return (
        <div className={styles['ProfileActions']}>
            <Password></Password>

            {(authorization.isAssumingIdentity || actionChecker.canDoAction('users.canAssumeIdentity.policy')) &&
            
                <div>
                    <Divider></Divider>
                    <Identity></Identity>
                </div>
            
            }
           
        </div>
    );
}