import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { windowSizeChanged } from "../store/slices/layout/layout.slice";
import Routes from "../routes/app.routes";
import { useAppDispatch } from "../store";
import { GlobalDebug } from "../config/remove-console";

const router = createBrowserRouter(Routes);

const App = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {

        function handleWindowSizeChanged() {
            dispatch(
                windowSizeChanged({
                    width: window.innerWidth,
                    height: window.innerHeight,
                })
            );
        }

        document.title = 'Intellek LMS';

        handleWindowSizeChanged();

        window.addEventListener("resize", handleWindowSizeChanged);

        if (process.env.NODE_ENV === "production") {
            GlobalDebug(false, false);
        }
        // Cleanup on component unmount
        return () => {
            window.removeEventListener("resize", handleWindowSizeChanged);
        };
    }, [dispatch]);  // Run this once on mount



    return (
        <RouterProvider router={router} />
    );
};

export default App;
