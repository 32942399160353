import {Link as ReactRouterLink} from 'react-router-dom';

import { Text } from '../text/Text';

import styles from './Link.module.scss';

interface IComponentProperties{
    to: string,
    text: string
}

export function Link(props: IComponentProperties){

    return (
        <ReactRouterLink className={styles['Link']} to={props.to}>
            <Text fontStyle='body-medium' value={props.text}></Text>
        </ReactRouterLink>
    )

}