import { Icon } from "../../../../common/components/material";

import styles from './SettingsEmptyPane.module.scss';

export function SettingsEmptyPane (){

    return (
        <div className={styles['SettingsEmptyPane']}>
            <Icon ariaHidden name="lightbulb" size={48}></Icon>
            <p>Select something first</p>
        </div>
    );

}