import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import { ActionStatus } from "../../config";
import { jobTitleService } from "./jobTitle.service";
import { JobTitle } from "../../../viewModels/user/jobTitle";
import { IjobTitleState } from "./jobTitle.types";

const pageStrings = new PageStrings();

const initialState: IjobTitleState = {
    data: [],
    error:'',
    status:ActionStatus.idle
}

export const getJobTitles = createAsyncThunk('jobtitles/get', async() =>{
    var response = await jobTitleService.getJobTitles();
    return response.value;
});

export const jobtitleSlice = createSlice({
    name: 'jobtitle',
    initialState,
    reducers: {     
    },
    extraReducers: (builder) => { 
        
        builder.addCase(getJobTitles.pending,(state:IjobTitleState)=>{
            state.status = ActionStatus.loading;
        });
        
        builder.addCase(getJobTitles.rejected,(state:IjobTitleState)=>{
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;

        });
        
        builder.addCase(getJobTitles.fulfilled,(state:IjobTitleState,action:PayloadAction<JobTitle[]>)=>{
            state.status = ActionStatus.succeeded;
            state.data = action.payload;
        });

    }
});

export default jobtitleSlice.reducer;
