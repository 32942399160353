import { Text } from "../text/Text";

import styles from './RadioGroup.module.scss';

interface IComponentProperties{
    label: string,
    children: React.ReactNode[]
}

export function RadioGroup (props: IComponentProperties){

    return (
        <div role="radiogroup" className={styles['RadioGroup']} aria-label={props.label}>
            <div className={styles['RadioGroup-label']} aria-hidden="true">
                <Text fontStyle="label-large" value={props.label}></Text>
            </div>

            {props.children}
        </div>
    );

}