import { NavigationBarItem } from './NavigationBarItem';
import { Elevation } from '../../../components/material/elevation/Elevation'

import styles from './NavigationBar.module.scss';
import { NavigationBarMoreMenuItem } from './moreMenu/NavigationBarMoreMenuItem';
import { useActionChecker, useNavigationItems } from '../../../hooks';
import { useAppSelector } from '../../../../store';

export function NavigationBar(){
    
    const actionChecker = useActionChecker();
    const configuration = useAppSelector(state=>state.configuration);
    const authorization = useAppSelector(state=>state.authorization);

    const navigationItems = useNavigationItems(actionChecker, configuration, authorization);

    const navigationBarItems = navigationItems.filter((item, i) =>{
        return navigationItems.length <= 5 || i < 4;
    }).map(item => {
        return  <NavigationBarItem key={item.id} labelText={item.labelText} iconName={item.iconName} 
            linkUrl={item.linkUrl} isActive={item.id === navigationItems[0].id}></NavigationBarItem>  
    });

    return (
        <nav aria-label="Primary" className={`${styles['NavigationBar']}`}>
            <Elevation></Elevation>
            <div className={styles["NavigationBar-background"]}></div>
            <div className={styles["NavigationBar-content"]}>
                <ul>
                    {navigationBarItems}
                    <NavigationBarMoreMenuItem navigationItems={navigationItems.slice(4)} ></NavigationBarMoreMenuItem>
                </ul>
            </div>
        </nav>
    );

}