import React from "react";
import { createComponent } from "@lit/react";
import { MdRadio } from "@material/web/radio/radio";
import { Text } from '../../text/Text';

import styles from "./Radio.module.scss";

const RadioComponent = createComponent({
    tagName: "md-radio",
    elementClass: MdRadio,
    react: React,
    events: {
        'onChange': 'change',
    }
});

interface IComponentProperties {
    checked: boolean,
    disabled?: boolean | undefined,
    labelText: string,
    name: string,
    value: string,
    onChanged?: Function
}

export function Radio(props: IComponentProperties) {
    
    const id = crypto.randomUUID();

    function handleChange(){
        if (props.onChanged){
            props.onChanged(props.value);
        }
    }

    return (
        <div className={styles['Radio']}>
            <div className={styles['Radio-target']}>
                <RadioComponent id={id} name={props.name} value={props.value} aria-labelledby={id}
                    checked={props.checked} disabled={props.disabled === true} onChange={handleChange} >
                </RadioComponent>
            </div>

            <label htmlFor={id}>
                <Text fontStyle="body-large" value={props.labelText}></Text>
            </label>
        </div>
    );
}