import { Snackbar } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useEffect } from "react";
import { dismissSnackbarItem } from "../../../../store/slices/snackbar";

export default function SnackbarManager() {

  const snackbar = useAppSelector((state) => state.snackbar);
  const dispatch = useAppDispatch();

  useEffect(() =>{

    if (snackbar.visible && snackbar.visibleItem){

      if(snackbar.visibleItem.autoDismissDelay)
      {
          setTimeout( ()=>{
              
              dispatch(dismissSnackbarItem());
          },snackbar.visibleItem.autoDismissDelay*1000);

      }

    }

  }, [snackbar, dispatch])

  return (
    <>
      {snackbar.visible && snackbar.visibleItem && (
        <>
          <Snackbar
            actionText={snackbar.visibleItem?.actionText}
            action={snackbar.visibleItem?.action }
            supportingText={snackbar.visibleItem?.message}
            configuration="single-line"
          ></Snackbar>
        </>
      )}
    </>
  );
}
